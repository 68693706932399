import { isEmpty } from "lodash";
import React from "react";
import "./styles.scss";

export const ModalOverviewExam = (props) => {
  const {
    open,
    onClose,
    dataAnswers,
    onShowQuestion,
    isReviewScreen,
    questionsReviewLater,
  } = props;

  if (!open) {
    return null;
  }

  const renderClassItemQuestion = (isDidExam, score, isReviewLater) => {
    if (isReviewScreen) {
      return parseInt(score) === 1 ? "answer-true" : "answer-false";
    } else {
      if (isReviewLater) {
        return "review-later";
      }
      if (isDidExam) {
        return "active";
      } else {
        return "not-active";
      }
    }
  };

  // console.log(dataAnswers);

  return (
    <div className="modal-overview-exam">
      <div className="layout"></div>
      <div className="modal-overview">
        <div className="modal-form">
          <div className="modal-header">
            <span className="title">Mục lục</span>
            <button className="btn btn-go-back" onClick={onClose}>
              Đóng
            </button>
          </div>
          <div className="modal-body">
            {isReviewScreen ? (
              <div className="note-question">
                <div className="item-note">
                  <div className="item-answer-true"></div>
                  <span>Câu đúng</span>
                </div>
                <div className="item-note">
                  <div className="item-answer-false"></div>
                  <span>Câu sai</span>
                </div>
              </div>
            ) : (
              <div className="note-question">
                <div className="item-note">
                  <div className="item-active"></div>
                  <span>Đã làm</span>
                </div>
                <div className="item-note">
                  <div className="item-review"></div>
                  <span>Xem lại sau</span>
                </div>
                <div className="item-note">
                  <div className="item-not-active"></div>
                  <span>Chưa làm</span>
                </div>
              </div>
            )}
            <div className="list-questions scrollbar-custom">
              {dataAnswers?.map((item, index) => {
                let isDidExam = !isEmpty(item.detail_user_turn[0]?.user_choice);
                let isReviewLater = questionsReviewLater?.includes(
                  item.question_id
                );
                let score = item.detail_user_turn[0]?.score;
                return (
                  <div
                    className={
                      "item-question " +
                      renderClassItemQuestion(isDidExam, score, isReviewLater)
                    }
                    key={index}
                    onClick={() => onShowQuestion(item)}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
