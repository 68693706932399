import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../_actions";
import { teacherService } from "../../_services";
import { Header } from "../../_components/Admin/Header";
import { isEmpty, findIndex } from "lodash";
import { studentService } from "../../_services";
import DoTestExamModal from "./exam-test/DoTestExam";
import { configConstants } from "../../_constants";
import LessonBase from "../../_base/LessonBase";
import { PopUpYesNo } from "../../_components/Popup";

const LEVEL_WITHOUT_SKILL = ["skill_guide", "project", "exam", "mini_test"];

function LessonCurriculumStudent() {
  const { skill, unit_id, id } = useParams();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const history = useHistory();
  const [itemExamConfirm, setItemExamConfirm] = useState();
  const [exeData, setexeData] = useState();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const page = params.get("page");
  const [examSelected, selectExam] = useState(null);
  const [showNavigateMasterUnit, setShowNavigateMasterUnit] = useState(false);
  const [message, setMessage] = useState("");

  // Check Lock Minitest
  const [lockMinitest, setLockMinitest] = useState(false);

  const curriculumStudent = useSelector((state) => state.curriculumStudent);
  // const [titleHeadear, setTitleHeadear] = useState("");
  const handleVisibilityChange = () => {
    if (document.visibilityState == "visible") {
      if (!["mini_test", "exam"].includes(skill)) {
        dispatch(studentActions.getLessonCurriculum(unit_id, true));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("pageshow", handleVisibilityChange);
    return () => {
      window.removeEventListener("pageshow", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    dispatch(studentActions.getLessonCurriculum(unit_id));
    var index = findIndex(curriculumStudent.data_map.data, { unit_id });
    if (index !== -1) {
      // setTitleHeadear(skill ? (skill.charAt(0).toUpperCase() + skill.slice(1)) : "");
    }
  }, []);

  const goToExercise = (data, from) => {
    // studentService.getLessonMasterUnit(data?.id).then((response) => {
    //   window.open(response.url, "_blank");
    // })

    if (curriculumStudent.lessons.require_learn_master_unit) {
      setShowNavigateMasterUnit(true);
    } else {
      if (
        ["mini_test", "exam"].includes(data?.exercise_type || data?.lesson_type)
      ) {
        // selectExam(data);
        if (!!data?.is_lock) {
          setMessage(data?.description || "");
          setLockMinitest(true);
        } else {
          selectExam(data);
        }
      } else if (
        (data.lesson_type === "project" ||
          (data.lesson_type === "writing" && data.question_type === "7") ||
          (data.lesson_type === "speaking" && data.question_type === "3")) &&
        parseInt(data?.status) !== -1
      ) {
        setItemExamConfirm(data);
        setexeData(data);
      } else {
        LessonBase._moveLesson(
          LessonBase.Type.curriculum,
          authentication,
          data,
          null,
          from
        );
      }
      // let typeUri = ''
      // if (['grammar', 'listening', 'pronunciation', 'reading', 'speaking', 'writing', 'vocabulary'].includes(data?.exercise_type || data?.lesson_type)) {
      //     typeUri = 'lesson'
      // } else if (['project'].includes(data?.exercise_type || data?.lesson_type)) {
      //     typeUri = 'project'
      // } else if (['homework'].includes(data?.exercise_type || data?.lesson_type)) {
      //     typeUri = 'homework'
      // } else {
      //     typeUri = 'lesson'
      // }
      // const url = `${configConstants.EXCERCISE_URL}index.php/example/lesson_demo?type=${typeUri}&id=${data?.lesson_id || data?.exercise_id}&token=${authentication?.jwt_token}`
      // console.log(url, '===> 3')
      // window.open(url, "_blank")
    }
  };

  const handleGoToHomework = () => {
    setItemExamConfirm(null);
    LessonBase._moveLesson(
      LessonBase.Type.curriculum,
      authentication,
      exeData,
      null,
      7
    );
    //goToExercise(item)
  };

  const goToHistoryExer = () => {
    setItemExamConfirm(null);
    history.push(
      "/" +
        authentication.role +
        `/curriculum/${id}/skill/${unit_id}/lesson/${skill}/${itemExamConfirm.lesson_id}/history`
    );
  };

  const handleCloseDoTest = () => {
    selectExam(null);
    dispatch(studentActions.getLessonCurriculum(unit_id, true));
  };

  return (
    <div className='sunE-right-container'>
      {showNavigateMasterUnit && (
        <PopUpYesNo
          onClickNo={() => setShowNavigateMasterUnit(false)}
          message={"Bạn hãy hoàn thành bài tập trong Master Unit để tiếp tục."}
          labelNo={"Đóng"}
          labelYes={'Đồng ý'}
          width={"280px"}
          onClickYes={() => {
            setShowNavigateMasterUnit(false)
            history.push(`/${authentication?.role}/curriculum/${id}/master_unit`)
          }}
          // hideButtonYes
        />
      )}
      {lockMinitest ? (
        <PopUpYesNo
          title='Thông báo'
          onClickYes={() => {
            setLockMinitest(false);
          }}
          message={
            message ||
            "Các bài tập Grammar phải đạt từ 7 điểm trở lên để mở bài kiểm tra Grammar."
          }
          labelYes={"Đóng"}
          hideButtonNo
        />
      ) : null}
      <Header
        title={
          skill
            ? (skill.charAt(0).toUpperCase() + skill.slice(1))?.replace(
                "_",
                " "
              )
            : ""
        }
        isBack
        clickBack={() =>
          history.push(
            "/" +
              authentication.role +
              `/curriculum/${id}/skill/${unit_id}?page=${page}`
          )
        }
      />
      <div
        className='sunE-container-box student_exercise scrollbar-custom'
        style={{ paddingLeft: 30 }}
      >
        {curriculumStudent.lessons.data.map((data, i) => {
          if (isEmpty(skill) || skill === data.skill) {
            return (
              <Fragment key={i}>
                {data.list_lesson.map((item, i) => {
                  let score = data.learned
                    ? (item.score || item.number_score) !== undefined
                      ? (item.score || item.number_score) + ""
                      : "__"
                    : "__";
                  return (
                    <div
                      className='student_exercise_item box-shadow rel'
                      key={i}
                    >
                      <div>
                        {item.is_assign_by_teacher && (
                          <div className='thumb-im'>
                            <img
                              src='/assets/images/student/ico_thumb.png'
                              alt='ico_thumb'
                            />
                          </div>
                        )}
                        {!LEVEL_WITHOUT_SKILL.includes(item?.lesson_type) &&
                          !_.isEmpty(item.level) && (
                            <div className='level'>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  goToExercise(item, 5);
                                  // LessonBase._moveLesson(LessonBase.Type.curriculum, authentication, item, null, 5)
                                }}
                              >
                                {item.level === "normal"
                                  ? "medium"
                                  : item.level}
                              </span>
                            </div>
                          )}
                        <div
                          style={{ cursor: "pointer" }}
                          className='student_exercise_item_info'
                          onClick={(e) => {
                            goToExercise(item, 6);
                            // LessonBase._moveLesson(LessonBase.Type.curriculum, authentication, item, null, 6)
                          }}
                        >
                          <h2>
                            {i + 1}. {item.topic || item.exam_name}
                          </h2>
                          <p>{item.lesson_name}</p>

                          <div className='flex-1'>
                            {(_.isEqual(item.lesson_type, "exam") ||
                              _.isEqual(item.lesson_type, "mini_test")) && (
                              <p>
                                {"Thời gian làm bài: " +
                                  (item.exam_type
                                    ? item.duration + " phút"
                                    : item.lesson_type === "mini_test"
                                    ? "15 phút"
                                    : "")}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='flex-m history'>
                        <div className='flex-1'>
                          {item.lesson_type !== "skill_guide" &&
                            item.learned && (
                              <Link
                                className='link-to-url'
                                to={
                                  "/" +
                                  authentication.role +
                                  `/curriculum/${id}/skill/${unit_id}/lesson/${skill}/${
                                    item.lesson_id
                                  }/history${
                                    !isEmpty(page) ? `?page=${page}` : ""
                                  }`
                                }
                              >
                                Lịch sử làm bài
                              </Link>
                            )}
                        </div>
                        {item.lesson_type !== "skill_guide" && (
                          <div className='scre'>
                            <p>Điểm: {score}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            );
          } else {
            return null;
          }
        })}
      </div>
      {examSelected && (
        <DoTestExamModal
          setExamSelected={selectExam}
          isShow={examSelected !== null}
          onClose={() => handleCloseDoTest()}
          classId={examSelected?.class_id}
          mockTestId={examSelected?.lesson_id || examSelected?.exercise_id}
          type={examSelected.lesson_type}
          heading={examSelected?.exam_name || examSelected?.name || examSelected?.lesson_name || examSelected?.exercise_name || ''}
        />
      )}
      {itemExamConfirm && (
        <PopUpYesNo
          onClickYes={() =>
            parseInt(itemExamConfirm?.status) !== 1
              ? handleGoToHomework()
              : goToHistoryExer()
          }
          onClickNo={() => setItemExamConfirm(null)}
          labelNo={"Không"}
          message={
            parseInt(itemExamConfirm?.status) === 0
              ? "Bạn đã nộp bài trước đó, bạn có chắc chắn muốn nộp lại?"
              : parseInt(itemExamConfirm?.status) === 1 &&
                "Bạn đã nộp bài và giáo viên đã chấm. Bạn có muốn xem bài chữa không?"
          }
        />
      )}
    </div>
  );
}

export { LessonCurriculumStudent };
