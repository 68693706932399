import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../_helpers/customHook/useOutsideClick";
import "./index.scss";
import classnames from "classnames";

// interface Props {
//     value: string | number;
//     setValue: (..._args: any[]) => void;
//     name: string;
//     placeholder?: string;
//     required?: boolean;
//     type: string;
//     className?: string;
//     label?: string;
//     style?: any;
//     isWarning?: boolean;
//     setIsWarning?: (..._args: any[]) => void;
//     setIcon?: () => any;
//     min?: number;
//     max?: number;
//     isHiddenClear?: boolean;
// }

const InputText = (props) => {
  const $inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const isFocusState = useRef(false);
  const [showPw, setShowPw] = useState(false);

  useEffect(() => {
    isFocusState.current = isFocus;
  }, [isFocus]);

  useOutsideClick($inputRef, () => {
    if (isFocusState.current) {
      setIsFocus(false);
    }
  });

  /**
   * togglePw
   */
  const togglePw = () => {
    setShowPw(!showPw);
  };

  /**
   * renderType: render type input
   */
  const renderType = () => {
    if (props.type === "password") {
      return !showPw ? "password" : "text";
    }
    return props.type;
  };

  /**
   * changeValue: input change value
   * @param e event
   */
  const changeValue = (e) => {
    // if (e.target.value === " ") {
    //   return;
    // }
    // if (
    //   e.target.value?.length > 2 &&
    //   e.target.value[e.target.value.length - 2] === " " &&
    //   e.target.value[e.target.value.length - 1] === " "
    // ) {
    //   return;
    // }
    if (props.removeWarningOnType && props.errorText) {
      props.setErrorText && props.setErrorText("");
    } else if (props.errorText && !e.target.value) {
      props.setErrorText && props.setErrorText("");
    }
    if (props.type === "password") {
      //   props.setValue(e.target.value?.trim());
      props.setValue(e.target.value);
    } else {
      props.setValue(e.target.value);
    }
  };

  // Handle Reset Value Search
  const handleResetValue = () => {
    props.handleResetValueSearch("reset");
  };

  // Handle Checked box
  const handleCheckedBoxValue = () => {
    props.handleCheckedBox();
  };

  return (
    <div className="input_text_base_container">
      {props.errorText && props?.typeErrText != "underAbsolute" ? (
        <div
          className={
            props.errorAbsolute
              ? "error_text_absolute error_input"
              : "error_text error_input"
          }
        >
          <span>{props.errorText}</span>
        </div>
      ) : null}
      <div
        ref={$inputRef}
        className={classnames(
          {
            focus: isFocus,
            warning: props.errorText || props?.compulWarning,
          },
          `${props.className ? props.className : ""} input_text_base ${
            props.readOnly && " bg_readonly"
          }`
        )}
        style={props.style}
      >
        <div className="input_text_base_content">
          {props.label ? <label className="">{props.label}</label> : null}
          <div className={`icon_label ${!props.renderLabelIcon && "hide"}`}>
            {props.renderLabelIcon ? props.renderLabelIcon() : null}
          </div>
          <input
            readOnly={props.readOnly}
            type={renderType()}
            name={props.name}
            placeholder={props.placeholder}
            required={props.required}
            className={`input_text one-line ${
              props.readOnly && " notallowed_cursor"
            }`}
            value={props.value}
            onChange={props.onChange ? props.onChange : changeValue}
            onFocus={() => {
              setIsFocus(true);
              props.onFocus && props.onFocus();
            }}
            min={props.min ? props.min : ""}
            max={props.max ? props.max : ""}
            onBlur={props.onBlur}
            autoComplete={
              props.autoComplete ? props.autoComplete : "new-password"
            }
            autoFocus={props?.autoFocus ? props.autoFocus : false}
          />
          {props.typeSearch == "search" && props.value ? (
            <div
              className="flex-center pointer_cursor no_select"
              onClick={handleResetValue}
            >
              <img
                src="/assets/images/icon/ico_close_menu.png"
                alt="Icon Delete Menu"
                className="contain_image img_action_input"
              />
            </div>
          ) : null}
          {props.typeSearch == "checkbox" && (
            <div
              className="flex-center pointer_cursor no_select"
              onClick={handleCheckedBoxValue}
            >
              <img
                src={`/assets/images/icon/${
                  props.isChecked ? "ico_checkedbox" : "ico_checkbox"
                }.png`}
                alt="Icon Checked Box"
                className="contain_image img_action_input"
              />
            </div>
          )}
        </div>
        {props.type !== "password" ? null : (
          <div className="toggle_password no_select" onClick={togglePw}>
            {showPw ? (
              <svg
                width="25"
                height="15"
                viewBox="0 0 25 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.93994 7.911C3.05705 6.07289 4.62958 4.55414 6.50543 3.50164C8.38129 2.44914 10.497 1.89853 12.6479 1.90301C14.7529 1.86419 16.8299 2.38922 18.6635 3.42367C20.4971 4.45812 22.0206 5.96431 23.0759 7.786C23.0759 7.786 19.8699 13.98 12.7409 13.98C5.61191 13.98 1.93994 7.911 1.93994 7.911Z"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.4918 11.978C14.7612 11.978 16.6008 10.1383 16.6008 7.86901C16.6008 5.59967 14.7612 3.76001 12.4918 3.76001C10.2225 3.76001 8.38281 5.59967 8.38281 7.86901C8.38281 10.1383 10.2225 11.978 12.4918 11.978Z"
                  fill="#707070"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.6792 7.70495C2.7704 5.90914 4.3066 4.42538 6.13922 3.39722C7.97185 2.36905 10.0389 1.83129 12.1402 1.83597C14.1968 1.79783 16.2261 2.31073 18.0176 3.32147C19.8091 4.33221 21.2974 5.80394 22.3282 7.58395C22.3282 7.58395 19.1962 13.636 12.2282 13.636C5.26021 13.636 1.6792 7.70495 1.6792 7.70495Z"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.9881 11.6779C14.205 11.6779 16.0021 9.88077 16.0021 7.6639C16.0021 5.44703 14.205 3.6499 11.9881 3.6499C9.77125 3.6499 7.97412 5.44703 7.97412 7.6639C7.97412 9.88077 9.77125 11.6779 11.9881 11.6779Z"
                  fill="#707070"
                />
                <path
                  d="M1.16797 14.363C21.495 2.295 22.326 1.521 22.326 1.521"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            )}
          </div>
        )}
      </div>

      {/* Err Text Under */}
      {props.errorText && props?.typeErrText == "underAbsolute" ? (
        <div
          className={
            props.errorAbsolute
              ? "error_text_absolute_new error_input"
              : "error_text error_input"
          }
        >
          <span>{props.errorText}</span>
        </div>
      ) : null}
    </div>
  );
};

export default InputText;
