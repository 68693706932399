import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../_actions";
import { Header } from "../../_components/Admin/Header";
import { findIndex } from "lodash";
import { useHistory } from "react-router-dom";
import { CricleProcess } from "../../_components/Process";
import { Alert } from "../../_components/Alert";
import { alertActions } from "../../_actions";
import { studentConstants } from "../../_constants";
import { convertSkillVN } from "../../_helpers/utils";

function SkillCurriculumStudent() {
  const { id, unit_id } = useParams();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const history = useHistory();
  const curriculumStudent = useSelector((state) => state.curriculumStudent);
  const authentication = useSelector((state) => state.authentication);
  // const [titleHeadear, setTitleHeadear] = useState("");

  const search = history.location.search;
  const params = new URLSearchParams(search);
  const page = params.get("page");

  useEffect(() => {
    dispatch(studentActions.getLessonCurriculum(unit_id));
    // var index = findIndex(curriculumStudent.data_map.data, { unit_id });
    // if (index !== -1) {
    //   setTitleHeadear(curriculumStudent.data_map.data[index].unit_name);
    // }
  }, []);

  const gotoLessionGramma = useCallback(
    (skill, isblocked) => {
      if (!isblocked) {
        history.push(
          `/${authentication.role}/curriculum/${id}/skill/${unit_id}/lesson/${skill}?page=${page}`
        );
      }
    },
    [curriculumStudent, authentication.role, id, unit_id]
  );

  const handleBack = () => {
    if (page === "homepage") {
      history.push("/" + authentication.role);
    } else {
      history.push("/" + authentication.role + "/curriculum/" + id);
    }
  };

  return (
    <div className="sunE-right-container">
      {alert.message &&
        alert.screen == studentConstants.SCREEN_LESSSON_SKILL && (
          <Alert alert={alert} onComplete={() => history.goBack()} />
        )}
      <Header
        title={
          curriculumStudent?.lessons?.data[0]?.list_lesson[0]?.unit_name || ""
        }
        isBack
        clickBack={handleBack}
      />
      <div className="sunE-container-box giaotrinh unit">
        <div className="flex-m">
          <div className="flex-2 sunE-giaotrinh-list scrollbar-custom pad-center-large">
            {/* {console.log(curriculumStudent?.lessons?.data[0]?.list_lesson[0]?.unit_name)} */}
            {curriculumStudent.lessons.data.map((data, i) => {
              return (
                <a
                  onClick={() => gotoLessionGramma(data.skill, data.is_blocked)}
                  key={i}
                  isblocked={data?.is_blocked}
                >
                  {i === 0 ? (
                    <div className={"box-giaotrinh-gr-first box-shadow"}>
                      <img
                        src={
                          "/assets/images/giaotrinh/" +
                          (data.skill === "test" || data.skill === "exam"
                            ? "mini_test"
                            : data.skill) +
                          ".png"
                        }
                        alt={data.skill}
                      />
                      <div className="item-skill-top">
                        {CricleProcess(
                          !data.is_blocked ? data.process_learn : null
                        )}
                        <h2 className="text-trans" style={{ marginLeft: 20 }}>
                          {convertSkillVN(data?.skill)}
                        </h2>
                      </div>
                    </div>
                  ) : (
                    <div className="box-giaotrinh-gr rel box-shadow">
                      <div className="thumb">
                        <img
                          src={
                            "/assets/images/giaotrinh/" +
                            (data.skill === "test" || data.skill === "exam"
                              ? "mini_test"
                              : data.skill) +
                            ".png"
                          }
                          alt={data.skill}
                        />
                      </div>
                      <div className="box-select-info flex-1">
                        <p className="text-trans semibold">
                          {convertSkillVN(data?.skill)}
                        </p>
                      </div>
                      {CricleProcess(
                        !data.is_blocked ? data.process_learn : null
                      )}
                    </div>
                  )}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export { SkillCurriculumStudent };
