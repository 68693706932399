export const convertSkillVN = (skill) => {
  switch (skill) {
          case 'vocabulary':
              return "Học từ vựng";
          case 'listening':
              return "Luyện nghe";
          case 'grammar':
              return "Học ngữ pháp";
          case 'speaking':
              return "Luyện nói";
          case 'reading':
              return "Luyện đọc";
          case 'writing':
              return "Luyện viết";
          case 'pronunciation':
              return "Học phát âm";
          case 'mini_test':
              return "Kiểm tra";
          case 'exam':
              return "Kiểm tra";
          case 'project':
              return "Bài tập lớn";          
      default:
          return ""
  }
}

export const convertSingleQuote = (str) => {
    if(typeof str !== 'string') return str;
    return str.replaceAll("‘", "'").replaceAll("’", "'").replaceAll("‛", "'");
}