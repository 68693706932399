import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherConstants } from "../../../../../_constants";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const detailSkillAssignLogic = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const userID = params.get("user_id");
  const authentication = useSelector((state) => state.authentication);

  const dataProposal = useSelector(
    (state) => state.classes.data_assign_proposal
  );

  const { id, type, lesson_id } = useParams();
  const exercises = useSelector((state) => state.classes.exercises);
  let temp_exercises_data_exercise = exercises.data_exercise;
  const skillArray = exercises.data_exercise.filter(
    (item) => item.lesson_id == lesson_id && item.user_id == userID
  );

  // Data Add student in exercise
  const dateAddStudentExercise = useSelector(
    (state) => state.classes.dateAddStudentExercise
  );
  const [skill] = skillArray;
  const skill_key = exercises.data_exercise.indexOf(skill) + 1;
  const skillKeyOfList = exercises.data_exercise[skill_key - 1];

  // console.log("skillKeyOfList ====", skillKeyOfList);
  // console.log("dateAddStudentExercise ====", skillKeyOfList);

  const [inputs, setInputs] = useState({
    detail: skillKeyOfList?.detail,
    exercise_type: skillKeyOfList?.exercise_type,
    list_guide_id: skillKeyOfList?.list_guide_id,
    parent: skillKeyOfList?.parent,
    start_time:
      skillKeyOfList?.start_time ??
      dateAddStudentExercise.start_time ??
      moment().format("YYYY-MM-DD"),
    end_time:
      skillKeyOfList?.end_time ??
      dateAddStudentExercise.end_time ??
      moment().add(2, "days").format("YYYY-MM-DD"),
  });

  let { start_time, end_time } = inputs;

  const location = useLocation();
  const stateReceived = location.state?.stateSaveTimePrev;
  const getPrevTime = useSelector((state) => state.classes.originTime);

  const originAttachFile = useSelector(
    (state) => state.classes.originAttachFile
  );
  const statusAttachFile = useSelector(
    (state) => state.classes.statusAttachFile
  );

  const [isEdit, setIsEdit] = useState(exercises?.isNew ?? false);

  useEffect(() => {
    if (!statusAttachFile) {
      dispatch({
        type: teacherConstants.ADD_FILE_ATTACH_ORIGIN,
        data: exercises.data_exercise[skill_key - 1]?.list_guide_id,
      });
    } else {
      setInputs((inputs) => ({
        ...inputs,
        start_time: getPrevTime.start_time ?? exercises.start_time,
        end_time: getPrevTime.end_time ?? exercises.end_time,
      }));
    }
    // dispatch({ type: teacherConstants.ON_STATUS_ATTACH_FILE });
  }, []);

  // Handle Change Data
  function handleChangeDate(date) {
    setIsEdit(true);
    if (date.start_time) {
      if (
        moment(date.start_time).toDate() <=
        moment(inputs.end_time, "DD-MM-YYYY").toDate()
      ) {
        setInputs((inputs) => ({
          ...inputs,
          start_time:
            moment(date.start_time).format("DD-MM-YYYY") || inputs.start_time,
        }));
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            start_time:
              moment(date.start_time).format("DD-MM-YYYY") || inputs.start_time,
          },
        });
      }
    } else if (date.end_time) {
      if (
        moment(date.end_time).toDate() >=
        moment(inputs.start_time, "DD-MM-YYYY").toDate()
      ) {
        setInputs((inputs) => ({
          ...inputs,
          end_time:
            moment(date.end_time).format("DD-MM-YYYY") || inputs.end_time,
        }));
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            end_time:
              moment(date.end_time).format("DD-MM-YYYY") || inputs.end_time,
          },
        });
      }
    }
    // setInputs(inputs => ({
    //     ...inputs,
    //     start_time: date.start_time ? moment(date.start_time).format("DD-MM-YYYY") : inputs.start_time,
    //     end_time: date.end_time ? moment(date.end_time).format("DD-MM-YYYY") : inputs.end_time,
    // }));
  }

  const [listFileId, setListFileId] = useState(
    exercises?.data_exercise[skill_key - 1]?.list_guide_id
  );

  // Handle Remove File
  function removeFile(key) {
    setIsEdit(true);
    const newArrayDeleted = listFileId.filter((_, index) => index !== key);
    // listFileId.splice(key, 1);
    setListFileId([...newArrayDeleted]);
    exercises.data_exercise[skill_key - 1].list_guide_id = newArrayDeleted;
    exercises.isNew = true;
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: exercises,
    });
  }

  // Handle Submit File Support and Time Assign
  function handleSubmit() {
    temp_exercises_data_exercise[skill_key - 1].start_time = start_time;
    temp_exercises_data_exercise[skill_key - 1].end_time = end_time;

    // Get DATA of Selected Student
    const [objStudentLessonAdd] = dataProposal.filter(
      (item) => item.user_id == userID
    );

    const [objLesson] = objStudentLessonAdd?.exercise_suggest.filter(
      (item) => item.lesson_id == lesson_id
    );

    objLesson.list_guide_id =
      temp_exercises_data_exercise[skill_key - 1].list_guide_id;
    objLesson.start_time = inputs.start_time;
    objLesson.end_time = inputs.end_time;

    const updatedObjects = objStudentLessonAdd?.exercise_suggest.map((obj) => {
      if (obj.lesson_id === lesson_id) {
        return { ...obj, ...objLesson };
      }
      return obj;
    });

    objStudentLessonAdd["exercise_suggest"] = updatedObjects;

    const newDataProposal = dataProposal;
    newDataProposal.forEach((item, index, dataOriginal) => {
      if (item.user_id == objStudentLessonAdd.user_id) {
        dataOriginal[index] = objStudentLessonAdd;
      }
    });

    dispatch({
      type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
      proposal: newDataProposal,
    });

    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...exercises,
        data_exercise: temp_exercises_data_exercise,
        start_time: inputs.start_time,
        end_time: inputs.end_time,
        isNew: false,
      },
    });
    dispatch({ type: teacherConstants.OFF_STATUS_ATTACH_FILE });
    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/student/" +
        type +
        "/list_proposals",
      {
        userIDSelected: userID,
      }
    );
  }

  // Validate Param Date
  function validateParam() {
    return new Date(inputs.start_time) > new Date(inputs.end_time)
      ? false
      : true;
  }

  // Handle Back
  const handleBack = () => {
    exercises.data_exercise[skill_key - 1].list_guide_id = originAttachFile;
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...exercises,
        data_exercise: temp_exercises_data_exercise,
        start_time: getPrevTime.startTime,
        end_time: getPrevTime.endTime,
        isNew: false,
      },
    });
    dispatch({ type: teacherConstants.OFF_STATUS_ATTACH_FILE });

    history.replace(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/student/" +
        type +
        "/list_proposals",
      {
        userIDSelected: userID,
      }
    );
  };

  // Handle Go List Support
  const handleGoListSupport = () => {
    dispatch({ type: teacherConstants.ON_STATUS_ATTACH_FILE });
    dispatch({
      type: teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN,
      data: {
        start_time: inputs.start_time,
        end_time: inputs.end_time,
      },
    });
  };

  return {
    skill_key,
    isEdit,
    inputs,
    stateReceived,
    getPrevTime,
    originAttachFile,
    skill,
    temp_exercises_data_exercise,
    listFileId,
    setInputs,
    setIsEdit,
    handleBack,
    validateParam,
    handleSubmit,
    handleChangeDate,
    removeFile,
    dispatch,
    handleGoListSupport,
  };
};
