import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userConstants } from "../../../_constants";
import { history } from "../../../_helpers";
import { teacherActions } from "../../../_actions";
import { getMobileOperatingSystem } from "../../../_base/Validate";
import { LinkApp } from "../../../_constants/linkDownloadApp";
import { persistor } from "../../../index";
import { convertNameHeaderItem } from "../../../_base/Validate";

export const headerNewsLogic = (props) => {
  let { type } = props;

  const _dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const dataProfile = useSelector((state) => state.profile.user_info.data);

  // useEffect(() => {
  //   _dispatch(teacherActions.getProfile(authentication.id));
  // }, []);

  const [isVisibleTaiff, setIsVisibleTariff] = useState(false);

  const [dataUser, setDataUser] = useState({
    fullname:
      JSON.parse(localStorage.getItem("info_header_user"))?.fullname ||
      dataProfile?.fullname,
    avatar:
      JSON.parse(localStorage.getItem("info_header_user"))?.avatar ||
      dataProfile?.avatar,
  });

  useEffect(() => {
    setDataUser({
      fullname:
        JSON.parse(localStorage.getItem("info_header_user"))?.fullname ||
        dataProfile?.fullname,
      avatar:
        JSON.parse(localStorage.getItem("info_header_user"))?.avatar ||
        dataProfile?.avatar,
    });
  }, [dataProfile]);

  const listHeader = [
    {
      id: 1,
      name: "TRANG CHỦ",
      type: TypeHeaderNewsItem.HOME,
    },
    {
      id: 2,
      name: "GIỚI THIỆU",
      type: TypeHeaderNewsItem.INTRODUCTION,
    },
    {
      id: 3,
      name: "KHÓA HỌC",
      type: TypeHeaderNewsItem.COURSES,
    },
    {
      id: 4,
      name: "BẢNG GIÁ",
      type:
        type == TypeHeaderNewsItem.TEACHER || type == TypeHeaderNewsItem.TARIFF
          ? TypeHeaderNewsItem.TARIFF
          : TypeHeaderNewsItem.FEE,
    },
    {
      id: 5,
      name: "PHỤ HUYNH",
      type: TypeHeaderNewsItem.PARENT,
    },
    {
      id: 6,
      name: "GIÁO VIÊN",
      type: TypeHeaderNewsItem.TEACHER,
    },
    {
      id: 7,
      name: "LUYỆN THI",
      type: TypeHeaderNewsItem.MOCK_TEST,
    },
  ];

  const [visibleModal, setVisibleModal] = useState(false);
  const [typeIcon, setTypeIcon] = useState("menu");
  const [isOpenModalNav, setIsOpenModalNav] = useState(false);

  const handleLogout = () => {
    setVisibleModal(false);
    _dispatch({
      type: userConstants.LOGOUT,
    });
    _dispatch({
      type: userConstants.RESET_ALL_STATE,
    });
    persistor.purge();
    // localStorage.clear();
    localStorage.removeItem("authentication");
    localStorage.removeItem("access_token");
    localStorage.removeItem("info_header_user");
    localStorage.removeItem("date_selected");
    localStorage.removeItem("curriculum_id_Selected");

    // localStorage.removeItem("device_id_commond");
    // window.location.href = "/";
    history.push("/");
  };

  const handleChangeModalHeader = () => {
    if (typeIcon == "menu") {
      setTypeIcon("close_menu");
      setIsOpenModalNav(true);
    } else {
      setTypeIcon("menu");
      setIsOpenModalNav(false);
      setIsVisibleTariff(false);
    }
  };

  useEffect(() => {
    if (window.location.href.includes("/fee/policy")) {
      document.title = convertNameHeaderItem("fee_policy");
    } else if (window.location.href.includes("/policy")) {
      document.title = convertNameHeaderItem("policy");
    } else if (window.location.href.includes("/faq")) {
      document.title = convertNameHeaderItem("faq");
    } else if (window.location.href.includes("/account_activation")) {
      document.title = convertNameHeaderItem("account_activation");
    } else {
      document.title = convertNameHeaderItem(type);
    }
  }, []);

  // Handle Tariff Open Sub Nav
  const handleOpenSubTariff = () => {
    setIsVisibleTariff(!isVisibleTaiff);
  };

  let wrapperRef = null;

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  function clickOutside(event) {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setIsVisibleTariff(false);
    }
  }

  const setWrapperRefFind = (node) => {
    if (!wrapperRef) {
      wrapperRef = node;
    }
  };

  // Data Tariff Select
  const dataSelectTariff = [
    {
      id: 1,
      name: "Dành cho học sinh",
      type: TypeHeaderNewsItem.FEE,
    },
    {
      id: 2,
      name: "Dành cho giáo viên",
      type: TypeHeaderNewsItem.TARIFF,
    },
  ];

  // Func Handle Select Option Tariff
  const handleSelectOptionTariff = (type) => {
    setTypeIcon("menu");
    setIsOpenModalNav(false);
    window.location.href = `/${type}`;
  };

  // Sticky Header
  useEffect(() => {
    var menu = document.querySelectorAll("div#header");
    var menu = menu[0];

    //Truy xuất div menu
    var trangthai = "duoi0.1";
    window.addEventListener("scroll", function () {
      var x = pageYOffset;
      if (x > 0.1) {
        if (trangthai == "duoi0.1") {
          trangthai = "tren20";
          menu.classList.add("header_sticky");
          menu.classList.add("box-shadow");
        }
      } else {
        if (trangthai == "tren20") {
          menu.classList.remove("header_sticky");
          menu.classList.remove("box-shadow");
          trangthai = "duoi0.1";
        }
      }
    });
  }, []);

  // Handle Navigate Curriculum
  const handleNavigateCurriculum = (type) => {
    const osMobile = getMobileOperatingSystem();
    var w = window.innerWidth;
    var h = window.innerHeight;
    if (osMobile == "Android" && h/w > 1.5) {
      window.open(LinkApp[`${type}`].GG_PLAY, "_blank");
    } else if (osMobile == "iOS") {
      window.open(LinkApp[`${type}`].APP_STORE, "_blank");
    } else {
      if (type === 'parent') {
        history.push('/download_page/parent')
        return;
      }
      if (authentication.isLogin) {
        history.push(`/${authentication.role}/curriculum`);
      } else {
        localStorage.setItem(
          "purposeLogin",
          JSON.stringify({
            purpose: "curriculum",
          })
        );
        history.push(`/login`);
      }
    }
  };

  return {
    isVisibleTaiff,
    listHeader,
    visibleModal,
    typeIcon,
    isOpenModalNav,
    dataUser,
    dataSelectTariff,
    setVisibleModal,
    handleLogout,
    handleChangeModalHeader,
    handleOpenSubTariff,
    setWrapperRefFind,
    handleSelectOptionTariff,
    handleNavigateCurriculum,
  };
};
