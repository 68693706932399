import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { studentActions } from "../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ChangView } from "../../_components/Admin/Curriculum";
import { studentService } from "../../_services";
import {
  curriculumConstants,
  teacherConstants,
  userConstants,
} from "../../_constants";
import _, { isEmpty, findIndex, isNull } from "lodash";
import { SelectAsDiv } from "../../_components/Select";
import $ from "jquery";
import { PopUpYesNo } from "../../_components/Popup";
import moment from "moment";
import styled from "styled-components";
import { ViewCurriculumSunEnglish } from "../TeacherPage/Curriculum/ViewCurriculumSunEnglish";
import { smoothScroll } from "../../_base/animsScroll";
import "./style.scss";
import LazyLoad from "react-lazyload";
import SelectCurriculum from "../../_components/Select/SelectCurriculum/SelectCurriculum";

const WrapImageBg = styled.div`
  /* background-image: ${(props) =>
    `url(${props.baseUrl + props.bgImages})`}; */
`;

function HomePageCurriculumStudent() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const curriculumStudent = useSelector((state) => state.curriculumStudent);
  const loading = useSelector((state) => state.curriculumStudent.loading);
  const authentication = useSelector((state) => state.authentication);

  const [listCurriculum, setListCurriculum] = useState({
    data: {
      list_curriculum: [],
    },
  });
  const [skillActive, setskillActive] = useState("");
  const [textDefault, setTextDefault] = useState("");
  const [typeCurriculum, setTypeCurriculum] = useState("");
  let [curriculumId, setCurriculumId] = useState(0);
  const [curriculumIdDefault, setCurriculumIdDefault] = useState(
    authentication?.curriculum_default
  );
  const [isConfirmSetDefault, confirmSetDefault] = useState(false);
  const [visibleModalLocked, setVisibleModalLocked] = useState(false);
  const [isShowNavigateMasterUnit, showNavigateMasterUnit] = useState(false);
  const [curriculumDefaultState, setCurriculumDefaultState] = useState(
    authentication?.curriculum_default
  );
  const isRootlessness = useSelector(
    (state) => state.rootlessness.isRootlessness
  );
  const chooseListCurriculum = useSelector(
    (state) => state.rootlessness.chooseListCurriculum
  );
  
  if (isEmpty(authentication.last_login) && isRootlessness) {
    history.push(`/${authentication.role}/`);
  }

  const [isRootlessnessStatus, setIsRootLessnessStatus] = useState(true);
  const [contentMapCurriculum, setContentMapCurriculum] = useState(
    document.querySelector("#sunE_map_curriculum")
  );

  const [msgLockUnit, setMsgLockUnit] = useState("");

  useEffect(() => {
    window.onpopstate = (event) => {
      if (
        window.location.pathname ===
          `/${userConstants.ROLE_STUDENT}/choose_curriculum` &&
        chooseListCurriculum
      ) {
        window.history.forward();
      }
    };
  }, []);

  useEffect(() => {
    let from = new URLSearchParams(window.location.search).get("from");
    if (from && curriculumStudent.data_map?.data_lesson?.unit_name) {
      let unit = curriculumStudent.data_map?.data_lesson?.unit_name[0];
      unit && gotoCurriculumSkill(unit?.unit_id, unit?.is_lock_unit);
    }
  }, [curriculumStudent.data_map?.data_lesson?.unit_name]);

  useEffect(() => {
    setContentMapCurriculum(document.querySelector("#sunE_map_curriculum"));
  }, [curriculumId]);

  useEffect(() => {
    const curriculumIdSelected = JSON.parse(
      localStorage.getItem("curriculum_id_Selected")
    );
    studentService.getListCurriculum().then((curriculums) => {
      setListCurriculum(curriculums);
      if (!chooseListCurriculum) {
        if (id) {
          let curriculumID = id;
          setCurriculumId(id);
          let defaultCurriculum = curriculums.data.list_curriculum.find(
            (item) => item.id == id
          );
          setCurriculumIdDefault(defaultCurriculum?.curriculum_id);
          if (!isEmpty(curriculumID)) {
            dispatch(studentActions.getDataMapByClassId(curriculumID));

            // Show curriculum by grid but now it not be used
            // dispatch(studentActions.getDataGridCurriculum(curriculumId));
          }
          var index = findIndex(curriculums.data.list_curriculum, {
            curriculum_id: defaultCurriculum.curriculum_id,
          });
          if (index !== -1) {
            setTextDefault(
              curriculums.data.list_curriculum[index].class_name
            );
          }
        } else if (curriculumIdSelected) {
          let defaultCurriculum = curriculums.data.list_curriculum.find(
            (item) => item.curriculum_id == curriculumIdSelected
          );
          setCurriculumId(defaultCurriculum?.id || curriculumIdSelected);
          setCurriculumIdDefault(defaultCurriculum?.curriculum_id);
          dispatch(studentActions.getDataMapByClassId(defaultCurriculum?.id));
          var index = findIndex(curriculums.data.list_curriculum, {
            curriculum_id: defaultCurriculum?.curriculum_id,
          });
          if (index !== -1) {
            setTextDefault(
              curriculums.data.list_curriculum[index].class_name
            );
          }
        } else {
          let defaultCurriculum = "";
          let curriculumID = "";
          if (!isEmpty(authentication?.curriculum_default)) {
            defaultCurriculum = curriculums.data.list_curriculum.find(
              (item) =>
                item?.curriculum_id == authentication?.curriculum_default
            );
            if (
              !isEmpty(defaultCurriculum) &&
              defaultCurriculum.curriculum_id ==
                authentication?.curriculum_default
            ) {
              setCurriculumDefaultState(authentication.curriculum_default);
            }
            setCurriculumIdDefault(authentication?.curriculum_default);
          }
          if (!isEmpty(defaultCurriculum)) {
            curriculumID = defaultCurriculum.id;
            setCurriculumId(defaultCurriculum.id);
          } else {
            if (!isEmpty(curriculums.data.list_curriculum)) {
              curriculumID = curriculums.data.list_curriculum[0].id;
              setCurriculumId(curriculums.data.list_curriculum[0].id);
              setCurriculumDefaultState(
                curriculums.data.list_curriculum[0].curriculum_id
              );
            }
          }
          // console.log('curriculumID ======', curriculums.data.list_curriculum)
          if (!isEmpty(curriculumID)) {
            isEmpty(authentication?.curriculum_default) &&
              dispatch({
                type: userConstants.LOGIN,
                user: {
                  ...authentication,
                  curriculum_default: curriculumID,
                },
              });
            dispatch(studentActions.getDataMapByClassId(curriculumID));

            // Show curriculum by grid but now it not be used
            // dispatch(studentActions.getDataGridCurriculum(curriculumId));
          }
          var index = findIndex(curriculums.data.list_curriculum, {
            curriculum_id: defaultCurriculum?.curriculum_id,
          });
          if (index !== -1) {
            setTextDefault(
              curriculums.data.list_curriculum[index].class_name
            );
          }
        }
      }
    });
  }, [isRootlessnessStatus]);

  useEffect(() => {
    // Scroll Map
    $(document).ready(function ($) {
      var down = false;
      var scrollLeft = 0;
      var x = 0;

      $(".sunE-content-unit")
        .mousedown(function (e) {
          down = true;
          scrollLeft = this.scrollLeft;
          x = e.clientX;
        })
        .mouseup(function () {
          down = false;
        })
        .mousemove(function (e) {
          var maxScroll = this.scrollWidth - this.clientWidth;
          if (down) {
            this.scrollLeft = scrollLeft + x - e.clientX;
            if (this.scrollLeft == 0) {
              setCanPrev(false);
            } else {
              setCanPrev(true);
              if (this.scrollLeft >= maxScroll) {
                setCanNext(false);
              } else {
                setCanNext(true);
              }
            }
          }
        })
        .mouseleave(function () {
          down = false;
        });
    });
  }, []);

  // Handle change Selected Curriulum
  const handleSelectedCurriculum = async (course) => {
    dispatch({ type: userConstants.OFF_CHOOSE_LIST_CURRICULUM });
    setIsRootLessnessStatus(false);
    await studentService.cancelEntranceExam();
    authentication?.isLogin &&
      dispatch({
        type: userConstants.LOGIN,
        user: {
          ...authentication,
          curriculum_default: course?.curriculum_id,
        },
      });
    updateCurriculumDefaultFirst(course.curriculum_id);
  };

  function onChangeSelect(key) {
    dispatch(studentActions.getDataMapByClassId(key));
    localStorage.setItem("curriculum_id_Selected", JSON.stringify(key));

    // Show curriculum by grid but now it not be used
    // dispatch(studentActions.getDataGridCurriculum(key, false));
    setCurriculumId(key);
    let curriculumIdSelect = listCurriculum.data.list_curriculum.find(
      (item) => item.id == key
    );
    var index = findIndex(listCurriculum.data.list_curriculum, {
      curriculum_id: curriculumIdSelect.curriculum_id,
    });
    const indexListCurriculum = findIndex(listCurriculum.data.list_curriculum, {
      id: key,
    });

    if (
      listCurriculum.data.list_curriculum[indexListCurriculum]?.curriculum_id ==
      authentication?.curriculum_default
    ) {
      setCurriculumIdDefault(authentication?.curriculum_default);
      setCurriculumDefaultState(
        listCurriculum.data.list_curriculum[indexListCurriculum]?.curriculum_id
      );
    } else {
      setCurriculumDefaultState(
        listCurriculum.data.list_curriculum[indexListCurriculum]?.curriculum_id
      );
    }

    if (index !== -1) {
      setTextDefault(
        listCurriculum.data.list_curriculum[index]?.curriculum_name
      );
    }
  }

  // Active Skill
  function activeSkill(skill) {
    setskillActive(skill);
    setnitIdSelected(false);
  }
  const [unitIdSelected, setnitIdSelected] = useState(false);

  const checkExist = (data) => {
    let isExist = false;
    curriculumStudent?.data_grid?.data?.lesson_data?.data?.map((item, i) => {
      if (item.lesson_type === data.id) {
        isExist = true;
      }
    });

    return isExist;
  };

  const updateCurriculumDefaultFirst = async (curriculum_id) => {
    authentication?.isLogin &&
      dispatch({
        type: userConstants.LOGIN,
        user: {
          ...authentication,
          curriculum_default: curriculum_id,
        },
      });
    if (chooseListCurriculum) {
      const res = await studentActions.updateDefaultCurriculumFirst(
        curriculum_id
      );
      if (res.status === true) {
        setCurriculumIdDefault(curriculum_id);
        confirmSetDefault(false);
        let user = JSON.parse(localStorage.getItem("authentication"));
        user.curriculum_default = curriculum_id;
        localStorage.setItem("authentication", JSON.stringify(user));
        dispatch({
          type: userConstants.LOGIN,
          user,
        });
      }
    } else {
      const res = await studentActions.updateDefaultCurriculumFirst(
        curriculumDefaultState
      );
      if (res.status === true) {
        setCurriculumIdDefault(curriculumDefaultState);
        confirmSetDefault(false);
        let user = JSON.parse(localStorage.getItem("authentication"));
        user.curriculum_default = curriculumDefaultState;
        localStorage.setItem("authentication", JSON.stringify(user));
        dispatch({
          type: userConstants.LOGIN,
          user,
        });
      }
    }
  };

  const baseUrl = useMemo(() => {
    return curriculumStudent?.data_map?.base_url;
  }, [curriculumStudent?.data_map]);

  const gotoCurriculumSkill = useCallback(
    (unitId, isLockUnit, dataUnit) => {
      const notTrial =
        authentication?.package?.remaining_day > 0 &&
        authentication?.package?.package_type != "Trial";

      if (!isLockUnit) {
        if (!curriculumStudent.data_map?.require_learn_master_unit) {
          return history.push(
            `/${authentication.role}/curriculum/${
              curriculumId || id
            }/skill/${unitId}`
          );
        } else {
          showNavigateMasterUnit(true);
        }
      } else {
        if (curriculumStudent.data_map?.require_learn_master_unit && notTrial) {
          showNavigateMasterUnit(true);
        } else {
          dataUnit.is_curriculum_loseroot && notTrial
            ? setMsgLockUnit(
                "Các bài kiểm tra của unit trước cần đạt tối thiểu 7 điểm để tiếp tục học!"
              )
            : setMsgLockUnit(null);
          setVisibleModalLocked(true);
        }
      }
    },
    [curriculumStudent, authentication.role, curriculumId]
  );

  const gotoUpgradePremiumAccount = () => {
    history.push(`/student/more/license`);
    setVisibleModalLocked(false);
  };

  // Handle Update Curriculum Default Commond
  const updateCurriculumDefaultCommond = async (curriculum_id) => {
    authentication?.isLogin &&
      dispatch({
        type: userConstants.LOGIN,
        user: {
          ...authentication,
          curriculum_default: curriculum_id,
        },
      });
    if (chooseListCurriculum) {
      const res = await studentActions.updateDefaultCurriculumFirst(curriculum_id);
      if (res.status === true) {
        setCurriculumIdDefault(curriculum_id);
        confirmSetDefault(false);
        let user = JSON.parse(localStorage.getItem("authentication"));
        user.curriculum_default = curriculum_id;
        localStorage.setItem("authentication", JSON.stringify(user));
        dispatch({
          type: userConstants.LOGIN,
          user,
        });
      }
    } else {
      const res = await studentActions.updateDefaultCurriculumFirst(
        curriculumDefaultState
      );
      if (res.status === true) {
        setCurriculumIdDefault(curriculumDefaultState);
        confirmSetDefault(false);
        let user = JSON.parse(localStorage.getItem("authentication"));
        user.curriculum_default = curriculumDefaultState;
        localStorage.setItem("authentication", JSON.stringify(user));
        dispatch({
          type: userConstants.LOGIN,
          user,
        });
      }
    }
  };
  
  const renderUnitList = useMemo(() => {
    const isTrial = authentication?.package?.package_type?.toLowerCase() === 'trial'
    return curriculumStudent.data_map.data_lesson.unit_name.map((data, i) => {
      let avatar = "/assets/images/giaotrinh/avt_default_unit.png";
      if (data.unit_avatar) {
        avatar = baseUrl + data.unit_avatar;
      }
      return (
        <div
          className='flex-column sunE-unit-item'
          key={"map" + i}
          style={{ position: "relative" }}
        >
          {i % 2 === 0 ? (
            <Fragment>
              <a
                draggable='false'
                style={{ zIndex: 10 }}
                onClick={() =>
                  gotoCurriculumSkill(data.unit_id, data.is_lock_unit, data)
                }
                // to={
                //   "/" +
                //   authentication.role +
                //   "/curriculum/" +
                //   curriculumId +
                //   "/skill/" +
                //   data.unit_id
                // }
              >
                <div className='flex-1 cs-point rel'>
                  <h2>{data.stt}</h2>
                  <p className='two-line'>{!loading ? data.unit_name : "-"}</p>
                  <div className='img-bg-x'>
                    <img
                      draggable='false'
                      className='__avt_unit'
                      src={avatar}
                    />
                  </div>
                  <div className='pink-ab'>
                    {
                    // _.isEqual(data.is_lock_unit, 1) ||
                    // curriculumStudent.data_map.require_learn_master_unit ||
                     (isTrial && _.isEqual(data.is_lock_unit, 1)) ? (
                      <img
                        src='/assets/images/student/ico_lock_pink.png'
                        alt='ico_lock_pink'
                      />
                    ) : (
                      <img
                        src='/assets/images/student/ico_check_pink.png'
                        alt='ico_check_pink'
                      />
                    // ) : (
                    //   // <span className='__percent_unit'>
                    //   //   {data.percentage_complete + "%"}
                    //   // </span>
                    //   <img
                    //     src='/assets/images/student/ico_check_pink.png'
                    //     alt='ico_check_pink'
                    //   />
                    )}
                  </div>
                </div>
              </a>
              <div className='flex-1'>
                <p className='h-287'>&nbsp;</p>
              </div>
              {curriculumStudent.data_map.data_lesson.unit_name.length - 1 !==
                i && (
                <img
                  className='__way_map'
                  src='/assets/images/icon/ico_dash_connect_2.png'
                />
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className='flex-1'>
                <p className='h-287'>&nbsp;</p>
              </div>
              <a
                draggable='false'
                style={{ zIndex: 10 }}
                onClick={() =>
                  gotoCurriculumSkill(data.unit_id, data.is_lock_unit, data)
                }
              >
                <div className='flex-1 cs-point rel'>
                  <h2>{data.stt}</h2>
                  <p className='two-line'>{!loading ? data.unit_name : "-"}</p>
                  <div className='img-bg-x'>
                    <img
                      draggable='false'
                      className='__avt_unit'
                      src={avatar}
                    />
                  </div>
                  <div className='pink-ab'>
                    {
                    // _.isEqual(data.is_lock_unit, 1) ||
                      // curriculumStudent.data_map.require_learn_master_unit || 
                      (isTrial && _.isEqual(data.is_lock_unit, 1)) ? (
                        <img
                          src='/assets/images/student/ico_lock_pink.png'
                          alt='ico_lock_pink'
                        />
                      ) : (
                        <img
                          src='/assets/images/student/ico_check_pink.png'
                          alt='ico_check_pink'
                        />
                      // ) : (
                      //   // <span className='__percent_unit'>
                      //   //   {data.percentage_complete + "%"}
                      //   // </span>
                      //   <img
                      //     src='/assets/images/student/ico_check_pink.png'
                      //     alt='ico_check_pink'
                      //   />
                      )}
                  </div>
                </div>
              </a>
              {curriculumStudent.data_map.data_lesson.unit_name.length - 1 !==
                i && (
                <img
                  className='__way_map_down'
                  src='/assets/images/icon/ico_dash_connect_1.png'
                />
              )}
            </Fragment>
          )}
        </div>
      );
    });
  }, [curriculumStudent.data_map.data_lesson.unit_name, loading]);

  // Define the amount to scroll by when the buttons are clicked
  const scrollAmount = 750;
  const animationDuration = 300;
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(true);

  const handleClickBtnMap = (type) => {
    switch (type) {
      case "prev":
        if (contentMapCurriculum.scrollLeft > 0) {
          setCanNext(true);
          smoothScroll(contentMapCurriculum, -scrollAmount, animationDuration);
          if (contentMapCurriculum.scrollLeft - scrollAmount <= 0) {
            setCanPrev(false);
          }
        } else {
          setCanPrev(false);
        }
        break;
      case "next":
        if (
          contentMapCurriculum.scrollLeft + contentMapCurriculum.clientWidth <
          contentMapCurriculum.scrollWidth
        ) {
          setCanPrev(true);
          smoothScroll(contentMapCurriculum, scrollAmount, animationDuration);
          if (
            contentMapCurriculum.scrollLeft +
              contentMapCurriculum.clientWidth +
              scrollAmount >=
            contentMapCurriculum.scrollWidth
          ) {
            setCanNext(false);
          }
        } else {
          setCanNext(false);
        }
        break;
      default:
    }
  };

  return (
    <>
      {
      // authentication.last_login == null && 
      chooseListCurriculum ? (
        <ViewCurriculumSunEnglish
          choose_list_curriculum={chooseListCurriculum}
          handleSelectedCurriculum={handleSelectedCurriculum}
        />
      ) : (
        <div className='sunE-right-container'>
          {isConfirmSetDefault && (
            <PopUpYesNo
              onClickYes={updateCurriculumDefaultCommond}
              onClickNo={() => confirmSetDefault(false)}
              message={
                "Bạn có chắc chắn muốn chuyển giáo trình này thành giáo trình ưu tiên."
              }
              labelNo={"Không"}
              labelYes={"Có"}
              width={"280px"}
            />
          )}
          {isShowNavigateMasterUnit && (
            <PopUpYesNo
              onClickYes={() => {
                localStorage.removeItem("tabSelectedMasterunit");
                history.push(
                  "/" +
                    authentication.role +
                    `/curriculum/${curriculumId}/master_unit`
                );
              }}
              onClickNo={() => showNavigateMasterUnit(false)}
              message={
                "Bạn hãy hoàn thành bài tập trong Master Unit để tiếp tục."
              }
              labelNo={"Đóng"}
              labelYes={"Đồng ý"}
              width={"280px"}
            />
          )}
          <div className='sunE-main-title flex-m'>
            <div
              className='map-skill rel mr-10'
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.removeItem("tabSelectedMasterunit");
                history.push(
                  "/" +
                    authentication.role +
                    `/curriculum/${curriculumId}/master_unit`
                );
              }}
            >
              <img
                src='/assets/images/student/Map-skill-final.png'
                alt='Map-skill-final'
              />
              <span className='box-shadow-2'>Master Unit</span>
            </div>
            <div className='flex-1 input-gr bar-select custom-select-no-bg '>
              <SelectCurriculum
                keyName={"class_name"}
                keySelected={curriculumId}
                textDefault={textDefault}
                data={listCurriculum.data.list_curriculum}
                onChangeSelect={(key) => onChangeSelect(key)}
                className='home_curriculum'
                position='left'
                typeName='type_class'
              />
            </div>
            {/* <div className="star mr-10">
              {_.isEqual(curriculumDefaultState, curriculumIdDefault) ? (
                <img src="/assets/images/student/ico_star.png" alt="ico_star" />
              ) : (
                <img
                  src="/assets/images/student/icon_star_default.png"
                  alt="ico_star"
                  onClick={() => confirmSetDefault(true)}
                />
              )}
            </div> */}
            {/* <ChangView student /> */}
          </div>
          {curriculumStudent.view === "map" ? (
            <div className='sunE-container-box giaotrinh unit  sunE_map_curriculum'>
              <div
                id='sunE_map_curriculum'
                className='sunE-content-unit scrollbar-custom'
              >
                <div className='sunE-unit-list'>{renderUnitList}</div>
              </div>
              <div className='footer_curriculum flex-center pd-top-4-pc'>
                <button
                  className='btn btn-primary'
                  onClick={() => handleClickBtnMap("prev")}
                  disabled={!canPrev}
                >
                  <img src='/assets/images/icon/ico_left_white.png' />
                </button>
                <button
                  className='btn btn-primary'
                  onClick={() => handleClickBtnMap("next")}
                  disabled={!canNext}
                >
                  <img src='/assets/images/icon/ico_right_white.png' />
                </button>
              </div>
              {!loading &&
                isEmpty(curriculumStudent.data_map.data_lesson.unit_name) && (
                  <div className='sunE-no-class text-center flex-1 mg-top-10'>
                    <img
                      src='/assets/images/student/bg_no_homework.png'
                      alt='img_no_class_big'
                    />
                    <div
                      className='bold'
                      style={{ fontSize: 26, color: "#707070" }}
                    >
                      Giáo trình lớp đang được cập nhật.
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div className='sunE-container-box giaotrinh unit student'>
              <div className='flex-m'>
                <LazyLoad className='flex-1 sunE-giaotrinh-list pr-25 pt-8 scrollbar-custom'>
                  {teacherConstants.TEXT_LIST_SKILL.map((data, i) => {
                    if (checkExist(data)) {
                      return (
                        <div
                          className={
                            skillActive === data.id
                              ? "box-giaotrinh-gr rel box-shadow active"
                              : "box-giaotrinh-gr rel box-shadow"
                          }
                          key={"left-" + i}
                          onClick={() => activeSkill(data.id)}
                        >
                          <div className='thumb'>
                            <img
                              src={
                                "/assets/images/giaotrinh/" +
                                (data.id === "test" || data.id === "exam"
                                  ? "mini_test"
                                  : data.id) +
                                ".png"
                              }
                              alt='ico_abc'
                            />
                          </div>
                          <div className='box-select-info flex-1'>
                            <p>{data.name}</p>
                          </div>
                          <div className='percent'></div>
                        </div>
                      );
                    }
                  })}
                </LazyLoad>
                <LazyLoad className='flex-1 pl-25 scrollbar-custom unit-list pt-8'>
                  {!isEmpty(curriculumStudent.data_grid.data.lesson_data) &&
                    curriculumStudent?.data_grid?.data?.lesson_data?.unit_name?.map(
                      (data, i) => {
                        let countShowItemParent = 0;
                        {
                          curriculumStudent?.data_grid?.data?.lesson_data.data?.map(
                            (item, i) => {
                              if (
                                item.unit_id === data.unit_id &&
                                (skillActive === "" ||
                                  item.lesson_type === skillActive)
                              ) {
                                countShowItemParent++;
                              }
                            }
                          );
                        }

                        return (
                          <Fragment key={"right-" + i}>
                            <div
                              className={
                                "unit-giaotrinh-content" +
                                (unitIdSelected === data.unit_id
                                  ? " active"
                                  : "") +
                                (!countShowItemParent ? " hide" : "")
                              }
                            >
                              <div className='box-giaotrinh-gr rel box-shadow'>
                                <div className='box-unit-info'>
                                  <p>{data.unit_name}</p>
                                </div>
                                {unitIdSelected === data.unit_id ? (
                                  <div
                                    className='btn-more-info center-flex'
                                    onClick={() => setnitIdSelected(false)}
                                  >
                                    <img
                                      src='/assets/images/giaotrinh/ico_show_.png'
                                      alt='-'
                                      className='show_img'
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className='btn-more-info center-flex'
                                    onClick={() =>
                                      setnitIdSelected(data.unit_id)
                                    }
                                  >
                                    <img
                                      src='/assets/images/giaotrinh/ico_add_black.png'
                                      alt='+'
                                    />
                                  </div>
                                )}
                              </div>
                              <LazyLoad className='unit-more-info'>
                                {curriculumStudent.data_grid.data.lesson_data.data.map(
                                  (item, i) => {
                                    if (
                                      item.unit_id === data.unit_id &&
                                      (skillActive === "" ||
                                        item.lesson_type === skillActive)
                                    ) {
                                      return (
                                        <div
                                          className='flex-m unit-more-info-item'
                                          key={"inner-" + i}
                                        >
                                          <div className='score'>
                                            {item.score && (
                                              <div className='score-box'>
                                                <h2>
                                                  {parseFloat(
                                                    item.score
                                                  ).toFixed(0)}
                                                </h2>
                                                <span>Điểm</span>
                                              </div>
                                            )}
                                          </div>
                                          <div className='flex-1 info-item-desc'>
                                            <Link
                                              to={
                                                "/" +
                                                authentication.role +
                                                "/curriculum/" +
                                                item.unit_id +
                                                "/skill"
                                              }
                                            >
                                              <h2 className='two-line'>
                                                {" "}
                                                {item.lesson_topic}
                                              </h2>
                                            </Link>
                                            <p>
                                              {item.lesson_name ||
                                                item.exam_name}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )}
                              </LazyLoad>
                            </div>
                          </Fragment>
                        );
                      }
                    )}
                </LazyLoad>
              </div>
            </div>
          )}

          {/* {_.isEmpty(curriculumStudent.data_map.data_lesson.unit_name) && <div className="sunE-container-box giaotrinh canhan flex-1">
          <div className="sunE-giaotrinh-list">
            <div className="box-giaotrinh-gr box-shadow hei-custom edit flex-m">
              <div className="box-select-info flex-1">
                <p>{curriculumStudent.data_grid?.data.curriculum_data?.name}</p>
                <span>Ngày gán:{" "}{moment(curriculumStudent.data_grid.data?.curriculum_data?.created_at)?.format("DD/MM/YYYY")}</span>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>} */}
          {visibleModalLocked && (
            <PopUpYesNo
              width={"360px"}
              onClickYes={!!msgLockUnit ? () => setVisibleModalLocked(false) : gotoUpgradePremiumAccount}
              onClickNo={() => setVisibleModalLocked(false)}
              message={
                msgLockUnit ||
                "Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!"
              }
              labelNo={"Đóng"}
              labelYes={!!msgLockUnit ? "Đóng" : "Nâng cấp"}
              hideButtonNo={!!msgLockUnit ? true : false}
            />
          )}
        </div>
      )}
    </>
  );
}
export { HomePageCurriculumStudent };
