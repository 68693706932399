import React, { useEffect } from "react";
import { teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { CricleProcess } from "../../../_components/Process";
import { history } from "../../../_helpers";
import { isEmpty } from "lodash";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { convertSkillVN } from "../../../_helpers/utils";

function ViewDetailCurriculumListSkill() {
  const { id, unit_id } = useParams();

  // useEffect(() => {
  //   dispatch(teacherActions.getDetailCurriculumById(id));
  // }, []);
  const dispatch = useDispatch();
  const curriculums = useSelector(
    (state) => state.curriculums.detail.data_lesson.unit_name
  );

  const lessonSkill = useSelector((state) => state.curriculums.lesson_skill);

  useEffect(() => {
    teacherService.getLessonBySkillTeacher(unit_id).then((res) => {
      if (res) {
        dispatch({
          type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
          lesson_skill: res,
        });
      }
    });
  }, []);

  let currentCurriculum = curriculums.find((item) => item.unit_id === unit_id);
  const authentication = useSelector((state) => state.authentication);

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");

  return (
    <div className="sunE-right-container">
      <Header title={currentCurriculum && currentCurriculum.unit_name} isBack />
      <div className="sunE-container-box giaotrinh unit">
        <div className="flex-m">
          <div className="flex-2 sunE-giaotrinh-list scrollbar-custom pad-center-large">
            {!isEmpty(lessonSkill?.data) &&
              lessonSkill?.data?.map((data, i) => {
                console.log(data)
                return (
                  <Link
                    to={
                      "/" +
                      authentication.role +
                      "/curriculum/view/detail/" +
                      id +
                      "/list_skill/" +
                      unit_id +
                      "/list_exercise/" +
                      data.skill +
                      (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
                    }
                    key={i}
                  >
                    {i === 0 ? (
                      <div
                        className={
                          "box-giaotrinh-gr-first box-shadow padding_box_giaotrinh_gr_frist"
                        }
                      >
                        <img
                          src={
                            "/assets/images/giaotrinh/" +
                            (data.skill === "test" || data.skill === "exam"
                              ? "mini_test"
                              : data.skill) +
                            ".png"
                          }
                          alt={data.skill}
                        />
                        <h2 className="text-trans">{convertSkillVN(data?.skill)}</h2>
                      </div>
                    ) : (
                      <div className="box-giaotrinh-gr rel box-shadow">
                        <div className="thumb">
                          <img
                            src={
                              "/assets/images/giaotrinh/" +
                              (data.skill === "test" || data.skill === "exam"
                                ? "mini_test"
                                : data.skill) +
                              ".png"
                            }
                            alt={data.skill}
                          />
                        </div>
                        <div className="box-select-info flex-1">
                          <p className="text-trans semibold">
                            {convertSkillVN(data?.skill)}
                          </p>
                        </div>
                        {/* { CricleProcess(40) } */}
                      </div>
                    )}
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ViewDetailCurriculumListSkill };
