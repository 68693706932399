import React, { useState } from "react";
import { useEffect } from "react";
import { studentService } from "../../../_services";
import {
  ExamGrammar1,
  ExamGrammar9,
  ExamPro1,
  ExamReading1,
} from "../../../_components/exam-test";
import { ExamListening } from "../../../_components/exam-test/ExamListening";
import { ExamWriting } from "../../../_components/exam-test/ExamWriting";
import { TimerTick } from "./DoTestExam";
import "./styles.scss";
import { ModalOverviewExam } from "./ModalOverview";
import { convertSingleQuote } from "../../../_helpers/utils";

export const ReviewExam = (props) => {
  const { isShow, onClose, listQuestions, resultExam, examInfo } = props;
  const [currentCategory, setCurrentCategory] = useState(0);
  const [indexQuestion, setIndexQuestion] = useState(1);
  const [isShowOverview, showOverview] = useState(false);
  
  const [questionSelected, setQuestionSelected] = useState({
    detail_user_turn: [],
    exercise_type: "",
    final_user_choice: "",
    question_id: "",
    question_score: "",
    question_type: "",
  });

  useEffect(() => {
    if (isShow) {
      setCurrentCategory(0);
      setIndexQuestion(1);
    }
  }, [isShow]);

  useEffect(() => {
    const element = document.getElementById(questionSelected.question_id);
    // console.log(element);
    element?.scrollIntoView();
  }, [questionSelected, currentCategory]);

  if (!isShow) {
    return null;
  }

  const backCategory = () => {
    if (currentCategory > 0) {
      setIndexQuestion(
        indexQuestion - listQuestions[currentCategory - 1].listQuestions?.length
      );
      setCurrentCategory(currentCategory - 1);
    }
  };

  const nextCategory = () => {
    if (currentCategory < listQuestions.length - 1) {
      setIndexQuestion(
        indexQuestion + listQuestions[currentCategory].listQuestions?.length
      );
      setCurrentCategory(currentCategory + 1);
    }
  };

  const formatListAnswer = () => {
    let listAnswers = [];
    listQuestions.forEach((category) => {
      category?.listQuestions?.forEach((question) => {
        let answer = resultExam.find(
          (item) => item.questionId === question.question_id
        )?.answer;
        let question_score = "0";
        if (
          ["grammar1", "reading14", "listening9"].includes(
            question.skill + question.question_type
          )
        ) {
          answer = convertSingleQuote(answer?.find((item) => item.type === "input").value?.trim());
        }
        if (
          [
            "grammar1",
            "reading14",
            "listening2",
            "listening9",
            "reading2",
            "reading3",
            "reading4",
            "reading11",
            "writing2",
            "writing3",
            "writing4",
            "writing5",
            "listening1",
          ].includes(question.skill + question.question_type)
        ) {
          let matchOptionText = question?.list_option[0].match_option_text?.map(
            (text) => convertSingleQuote(text.toLowerCase())
          );
          if (question.skill + question.question_type === "writing2") {
            matchOptionText = [
              question?.list_option[0].question_content
                ?.replaceAll("/", "")
                ?.toLowerCase(),
            ];
          }

          if (
            matchOptionText?.includes(
              answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
            ) ||
            matchOptionText?.includes(
              answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat(".")
            ) ||
            matchOptionText?.includes(
              answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat("?")
            )
          ) {
            question_score = question.list_option[0].score;
          }
        } else {
          let option = question?.list_option?.find((item) =>
            item?.match_option_text?.includes(answer)
          );
          question_score = option?.score || "0";
        }
        listAnswers.push({
          exercise_type: question.skill,
          final_user_choice: "",
          question_id: question.question_id,
          question_score: question_score,
          question_type: question.question_type,
          detail_user_turn: [
            {
              num_turn: 1,
              score: question_score,
              user_choice: answer,
            },
          ],
        });
      });
    });
    return listAnswers;
  };

  const onShowQuestion = (question) => {
    let sttQuestion = 1;
    setQuestionSelected(question);

    listQuestions.forEach((category, index) => {
      category?.listQuestions?.forEach((item) => {
        if (item.question_id === question.question_id) {
          setCurrentCategory(index);
          showOverview(false);
          setIndexQuestion(sttQuestion);
        }
      });
      sttQuestion = sttQuestion + category.listQuestions?.length;
    });
  };

  const renderQuestion = (category) => {
    const indexQuestion = category?.listQuestions?.[0]?.questionNumber || 1
    if (category.skill === "pronunciation") {
      return (
        <ExamPro1
          isReview={true}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "reading") {
      return (
        <ExamReading1
          isReview={true}
          type={"reading" + category?.question_type}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "writing") {
      return (
        <ExamWriting
          type={"writing" + category?.question_type}
          isReview={true}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "listening") {
      return (
        <ExamListening
          type={"listening" + category?.question_type}
          isReview={true}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "grammar") {
      if (category?.question_type === "1") {
        return (
          <ExamGrammar1
            category={category}
            isReview={true}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) => {}}
          />
        );
      } else if (
        category?.question_type === "9" ||
        category?.question_type === "11"
      ) {
        return (
          <ExamGrammar9
            category={category}
            isReview={true}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) => {}}
          />
        );
      }
    }
  };

  return (
    <div id="c-do-test-exam">
      <div className="sunE-container rel flex bg-main-img">
        <div className="header-modal">
          <div className="title-header" onClick={onClose}>
            <img src="/assets/images/icon/ico_left.png" />
            <span style={{ marginLeft: 30 }}>
              {`${
                !["mini_test", "exam", "remind"].includes(
                  examInfo?.lesson_type ||
                    examInfo?.exercise_type ||
                    examInfo?.type
                )
                  ? "Bài thi "
                  : ""
              }${examInfo?.name || examInfo?.exam_name || ""}`}
            </span>
          </div>
          <div className="text-right">
            <button className="btn btn-overview hide">Video chữa bài</button>
            <button
              className="btn btn-overview"
              onClick={() => showOverview(true)}
            >
              Mục lục
            </button>
          </div>
        </div>
        <div className="flex-1 scrollbar-custom">
          {/* <div className="questions">
            {listQuestions.length > 0 &&
              renderQuestion(listQuestions[currentCategory])}
          </div> */}
          {listQuestions.length > 0 &&
              listQuestions?.map((item, index) => (
                <div className="questions" key={item?.group_id}>
                  {renderQuestion(item)}
                </div>
              ))
            }
        </div>
        <div className="footer-exam">
          {/* <button
            className="btn btn-primary"
            onClick={backCategory}
            disabled={currentCategory === 0}
          >
            <img src="/assets/images/icon/ico_left_white.png" />
          </button>
          <TimerTick seconds={15 * 60} disabled={true}/>
          <button
            className="btn btn-primary"
            onClick={nextCategory}
            disabled={currentCategory === listQuestions.length - 1}
          >
            <img src="/assets/images/icon/ico_right_white.png" />
          </button> */}
        </div>
      </div>
      {isShowOverview && (
        <ModalOverviewExam
          isReviewScreen={true}
          open={isShowOverview}
          onClose={() => showOverview(false)}
          dataAnswers={formatListAnswer()}
          onShowQuestion={onShowQuestion}
        />
      )}
    </div>
  );
};
