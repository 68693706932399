import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  ExamGrammar1,
  ExamGrammar9,
  ExamPro1,
  ExamReading1,
} from "../../../_components/exam-test";
import { ExamListening } from "../../../_components/exam-test/ExamListening";
import { ExamWriting } from "../../../_components/exam-test/ExamWriting";
import { PopUpYesNo } from "../../../_components/Popup/PopUpYesNo";
import { studentService } from "../../../_services/students";
import { ModalOverviewExam } from "./ModalOverview";
import ResultExam from "./ResultExam";
import InstructTestExam from "./InstructTestExam";
import CheckBoxSquare from "../../../_components/exam-test/CheckBoxSquare";
import "./styles.scss";
import $ from "jquery";
import { userConstants } from "../../../_constants";
import { convertSingleQuote } from "../../../_helpers/utils";

const formatTwoNumber = (num) => {
  return num >= 10 ? num : "0" + num;
};

var intervalId = null;
export const TimerTick = (props) => {
  const { seconds, onEndTimerTick, disabled } = props;
  const [currentSeconds, setCurrentSeconds] = useState(seconds);

  useEffect(() => {
    if (!disabled) {
      clearInterval(intervalId);
      let count = seconds;
      intervalId = setInterval(() => {
        count = count - 1;
        setCurrentSeconds(count);
        if (count <= 0) {
          clearInterval(intervalId);
          onEndTimerTick();
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [seconds]);

  return (
    <div className="real-timing">
      {formatTwoNumber(parseInt(currentSeconds / 60))}:
      {formatTwoNumber(currentSeconds % 60)}
    </div>
  );
};

const DoTestExamModal = (props) => {
  const {
    isShow,
    onClose,
    mockTestId,
    type,
    classId,
    unitId,
    isHomeWork,
    typeMasterUnit,
    masterUnitId,
    typeMockTest,
    rootlessness,
    examSelected,
    setExamSelected,
    heading = ''
  } = props;

  const { unit_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  const [listQuestions, setListQuestions] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [duration, setDuration] = useState(0);
  const [indexQuestion, setIndexQuestion] = useState(1);
  const [isDoing, setDoingExam] = useState(type == "mock_test" ? false : true);
  const [examInfo, setExamInfo] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [resultExam, setResultExam] = useState([]);
  const [isShowOverview, showOverview] = useState(false);
  const [actionConfirm, setActionConfirm] = useState({});
  const [dataResult, setDataResult] = useState();
  const [messageError, setMessageError] = useState("");
  const [detailMockTest, setDetailMockTest] = useState([]);
  const [checkedList, setCheckedList] = useState([
    {
      category: 0,
      checked: false,
    },
  ]);

  const [questionsReview, setQuestionsReview] = useState([]);

  const [questionSelected, setQuestionSelected] = useState({
    detail_user_turn: [],
    exercise_type: "",
    final_user_choice: "",
    question_id: "",
    question_score: "",
    question_type: "",
  });

  const [msgPopup, setMsgPopup] = useState("");

  const [listReview, setListReview] = useState([])

  if (!isShow) {
    return null;
  }

  useEffect(() => {
    // if (rootlessness) {
      setDoingExam(false);
      // startDoExam();
      setLoading(false);
      setMsgPopup("");
    // } else if (isShow && mockTestId && type != "mock_test") {
    //   setDoingExam(true);
    //   startDoExam();
    // }
    if (!typeMasterUnit && type == "mock_test") {
      const getDetailMockTest = async () => {
        const res = await studentService?.getDetailMockTest(mockTestId);

        if (res?.status) {
          setDetailMockTest(res?.mock_test_data);
          type == "mock_test" ? setDoingExam(false) : setDoingExam(true);
        }
      };

      getDetailMockTest();
    }
  }, [isShow, mockTestId]);

  const startDoExam = async () => {
    try {
      setLoading(true);
      if (rootlessness) {
        setDoingExam(true);
        const res = await studentService.getPlacementTest();
        if (res?.status == true) {
          let listData = formatDataQuestions(res);
          setListQuestions(listData);
          setDuration(authentication.grade_id == 2 ? 25 : 30);
          // setDuration(parseInt(res?.lesson?.duration) || 45);
          setExamInfo({
            ...(res?.lesson || res?.exam),
            user_exam_result_id: res?.user_exam_result_id,
          });
        }
      } else if (typeMasterUnit) {
        const res = await studentService.startDoMasterUnit(
          masterUnitId,
          typeMasterUnit
        );
        if (res?.status) {
          let listData = formatDataQuestions(res);
          setListQuestions(listData);
          // setDuration(parseInt(res?.lesson?.duration) || 45);
          setDuration(
            parseInt(res?.lesson?.duration) ||
              parseInt(res?.exam?.duration) ||
              45
          );
          setExamInfo({
            ...res?.lesson,
            user_exam_result_id: res?.user_exam_result_id,
            looking_back_utilize_id: res?.looking_back_utilize_id,
          });
        }
      } else {
        const res = await studentService.startDoExamService(
          mockTestId,
          type,
          classId,
          unit_id || unitId,
          isHomeWork,
          examSelected?.curriculum_id
        );

        if (res?.status) {
          let listData = formatDataQuestions(res);
          setListQuestions(listData);
          setDuration(
            parseInt(res?.lesson?.duration) ||
              parseInt(res?.exam?.duration) ||
              45
          );
          setExamInfo({
            ...(res?.lesson || res?.exam),
            user_exam_result_id: res?.user_exam_result_id,
          });
        }
        setDoingExam(true);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMsgPopup(e?.toString());
    }
  };

  // console.log(duration);

  // console.log(examInfo?.duration);

  const formatDataQuestions = (data) => {
    let listData = [];
    data?.data_group_question.forEach((item) => {
      item.listQuestions = [];
      data?.data_question?.forEach((options) => {
        let isCheckReading3 =
          item.skill === options?.list_option[0]?.skill &&
          item?.question_type === options?.list_option[0]?.question_type &&
          item.skill + item.question_type === "reading3";
        let isCheckReading2 =
          item.skill === options?.list_option[0]?.skill &&
          item?.question_type === options?.list_option[0]?.question_type &&
          item.skill + item.question_type === "reading2";
        if (
          item.group_id === options?.list_option[0]?.group_id ||
          item.group_id === options?.list_option[0]?.category_id
        ) {
          let {
            group_content,
            group_content_vi,
            question_content,
            skill,
            question_type,
            group_script,
            jamming_answer_parse,
            content,
            match_option_text,
            group_file,
            hint,
            explain_parse,
            option_explain,
            group_title,
          } = options?.list_option[0];
          let question = {
            ...options,
            group_content,
            group_content_vi,
            question_content,
            skill,
            question_type,
            hint,
            explain_parse,
            option_explain,
          };
          item.listQuestions = [...item.listQuestions, question];
          item.group_content = group_content;
          item.content = content;
          item.group_script = group_script;
          item.group_title = group_title;
          if (item.skill + item.question_type === "reading3") {
            item.group_script = question_content;
            item.jamming_answer_parse = jamming_answer_parse;
          } else if (
            item.skill + item.question_type === "reading2" ||
            item.skill + item.question_type === "reading4"
          ) {
            item.match_option_text = match_option_text;
          } else if (item.skill + item.question_type === "writing2") {
            let textQuestion = question?.question_content
              .split("/")
              .sort(() => Math.random() - 0.5);
            question.question_content = textQuestion.join("/");
          }
          if (item.skill === "listening") {
            item.group_file = group_file;
          }
        }
      });
      if (
        item.skill + item.question_type === "reading3" ||
        item.skill + item.question_type === "reading2" ||
        item.skill + item.question_type === "reading4"
      ) {
        if (
          item.skill + item.question_type === "reading3" &&
          listData.filter(
            (data) =>
              data.skill + data.question_type === "reading3" &&
              (item.group_id === data.group_id ||
                item.group_id === data.category_id)
          ).length === 0
        ) {
          listData.push(item);
        } else if (
          item.skill + item.question_type === "reading4" &&
          listData.filter(
            (data) =>
              data.skill + data.question_type === "reading4" &&
              (item.group_id === data.group_id ||
                item.group_id === data.category_id)
          ).length === 0
        ) {
          listData.push(item);
        } else if (
          item.skill + item.question_type === "reading2" &&
          listData.filter(
            (data) =>
              data.skill + data.question_type === "reading2" &&
              (item.group_id === data.group_id ||
                item.group_id === data.category_id)
          ).length === 0
        ) {
          listData.push(item);
        }
      } else {
        listData.push(item);
      }
    });
    let questionNumber = 1
    let listReview = []
    const result = listData?.map(item => {
      const listQuestions = item?.listQuestions?.map(question => {
        const newQuestion = {
          ...question,
          questionNumber,
          list_option: question?.list_option?.map(option => ({
            ...option,
            match_option_text: typeof option?.match_option_text === "string" ? convertSingleQuote(option?.match_option_text) : option?.match_option_text?.map(item => {
              if (typeof item === 'string') {
                return convertSingleQuote(item)
              }
              return item;
            })
          }))
        }
        listReview.push({
          question_id: question?.question_id,
          checked: false,
        })
        questionNumber += 1
        return newQuestion
      })
      return {
        ...item,
        listQuestions,
      }
    })
    setListReview(listReview)
    // return listData;
    return result
  };

  const onChangeResult = (questionId, answer) => {
    let list = [...resultExam];
    var index = list.findIndex((img) => img.questionId === questionId);
    if (index !== -1) {
      list[index] = { questionId, answer };
      setResultExam(list);
    } else {
      setResultExam([...list, { questionId, answer }]);
    }
  };

  const backCategory = () => {
    if (currentCategory > 0) {
      setIndexQuestion(
        indexQuestion - listQuestions[currentCategory - 1].listQuestions?.length
      );
      setCurrentCategory(currentCategory - 1);
    }
  };

  const nextCategory = () => {
    if (currentCategory < listQuestions.length - 1) {
      setIndexQuestion(
        indexQuestion + listQuestions[currentCategory].listQuestions?.length
      );
      setCurrentCategory(currentCategory + 1);

      const newCheckedList = [...checkedList];
      !newCheckedList.some((item) => item.category == currentCategory + 1) &&
        setCheckedList([
          ...newCheckedList,
          { category: currentCategory + 1, checked: false },
        ]);
    }
  };

  const formatListAnswer = () => {
    let listAnswers = [];
    listQuestions.forEach((category) => {
      category?.listQuestions?.forEach((question) => {
        let answer = resultExam.find(
          (item) => item.questionId === question.question_id
        )?.answer;
        let question_score = "0";
        if (
          ["grammar1", "reading14", "listening9"].includes(
            question.skill + question.question_type
          )
        ) {
          answer = convertSingleQuote(answer?.find((item) => item.type === "input").value?.trim());
        }
        if (
          [
            "grammar1",
            "reading14",
            "listening2",
            "listening9",
            "reading2",
            "reading3",
            "reading4",
            "reading11",
            "writing2",
            "writing3",
            "writing4",
            "writing5",
            "listening1",
          ].includes(question.skill + question.question_type)
        ) {
          let matchOptionText = question?.list_option[0].match_option_text?.map(
            (text) => convertSingleQuote(text?.toLowerCase()?.trim())
          );
          if (question.skill + question.question_type === "writing2") {
            matchOptionText = [
              question?.list_option[0].question_content
                ?.replaceAll("/", "")
                ?.toLowerCase()
                ?.trim(),
            ];
          }
          if (
            matchOptionText?.includes(
              answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
            ) ||
            matchOptionText?.includes(
              answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat(".")
            ) ||
            matchOptionText?.includes(
              answer
                ?.toLowerCase()
                ?.trim()
                ?.replace(/[^A-Za-z0-9]+$/, "")
                ?.replace(/\s+/g, " ")
                ?.concat("?")
            )
          ) {
            question_score = question.list_option[0].score;
          }
        } else {
          let option = question?.list_option?.find((item) =>
            item?.match_option_text?.includes(answer)
          );
          question_score = option?.score || "0";
        }
        listAnswers.push({
          exercise_type: question.skill,
          final_user_choice: "",
          question_id: question.question_id,
          question_score: question_score,
          question_type: question.question_type,
          detail_user_turn: [
            {
              num_turn: 1,
              score: question_score,
              user_choice: answer?.trim(),
            },
          ],
        });
      });
    });
    return listAnswers;
  };

  const saveExamTest = async () => {
    try {
      let listDataAnswer = formatListAnswer();
      if (rootlessness) {
        let params = {
          data_answer: JSON.stringify(listDataAnswer),
          data_exam: JSON.stringify({ ...examInfo }),
          type: examInfo.lesson_type,
          class_id: authentication.class,
          exam_id: examInfo.id,
          user_exam_result_id: examInfo.user_exam_result_id,
        };
        const res = await studentService.saveExamService(params);
        setDataResult(res);
        dispatch({
          type: userConstants.OFF_PLACEMENT_TEST,
        });
        dispatch({ type: userConstants.ON_PROPOSED_CURRICULUM });
        dispatch({ type: userConstants.SHOW_SIDEBAR_ROOTLESSNESS })
        setActionConfirm("");
        history.push("/" + authentication.role + "/choose_curriculum");
        setDoingExam(false);
      } else if (typeMasterUnit) {
        let params = {
          data_answer: JSON.stringify(listDataAnswer),
          data_exam: JSON.stringify({ ...examInfo }),
          type: typeMasterUnit,
          exam_id: mockTestId,
          class_id: classId,
          is_homework: isHomeWork || type === "mock_test" ? 1 : 0,
          looking_back_utilize_id: examInfo.looking_back_utilize_id,
        };
        const res = await studentService.saveExamService(params);
        setDataResult(res);
        setActionConfirm("");
        setDoingExam(false);
      } else {
        let params = {
          data_answer: JSON.stringify(listDataAnswer),
          data_exam: JSON.stringify({ ...examInfo }),
          type: type,
          exam_id: mockTestId,
          class_id: classId,
          unit_id: unit_id || unitId,
          user_exam_result_id: examInfo.user_exam_result_id,
          is_homework: isHomeWork || type === "mock_test" ? 1 : 0,
        };
        const res = await studentService.saveExamService(params);
        setDataResult(res);
        setActionConfirm("");
        setDoingExam(false);
      }
    } catch (e) {
      setActionConfirm("");
      setMessageError(e.toString());
      // dispatch(alertActions.error({ 'message': e.toString(), 'screen': ''}))
    }
  };

  const actionCancelExam = () => {
    if (isDoing) {
      setActionConfirm("CANCEL_EXAM");
      showOverview(false);
    } else {
      onClose();
    }
  };

  const actionSaveExam = () => {
    if (isDoing) {
      setActionConfirm("SAVE_EXAM");
      showOverview(false);
    } else {
      saveExamTest();
    }
  };

  const actionEndExam = () => {
    if (isDoing) {
      setActionConfirm("END_EXAM");
    }
    showOverview(false);
  };

  const onShowQuestion = (question) => {
    let sttQuestion = 1;
    let currCategoryId = 0;
    setQuestionSelected(question);

    listQuestions.forEach((category, index) => {
      category?.listQuestions?.forEach((item) => {
        if (item.question_id === question.question_id) {
          currCategoryId = index;
          setCurrentCategory(index);
          showOverview(false);
          setIndexQuestion(sttQuestion);
        }
      });
      sttQuestion = sttQuestion + category.listQuestions?.length;
    });

    const newCheckedList = [...checkedList];

    const prevCheckedList = [];

    for (var i = newCheckedList.length; i <= currCategoryId; i++) {
      // !newCheckedList.some((item) => item.category == currCategoryId) &&
      prevCheckedList.push({ category: i, checked: false });
    }
    setCheckedList(newCheckedList.concat(prevCheckedList));
  };

  useEffect(() => {
    const element = document.getElementById(questionSelected.question_id);
    // console.log(element);
    element?.scrollIntoView();
  }, [questionSelected, currentCategory]);

  const handleCheckReview = (question_id) => {
    const newListReview = listReview?.map(item => {
      if(item?.question_id === question_id) {
        return {...item, checked:!item.checked}
      }
      return item
    })
    setListReview(newListReview)
  }
  
  const renderQuestion = (category) => {
    const indexQuestion = category?.listQuestions?.[0]?.questionNumber
    if (category.skill === "pronunciation") {
      return (
        <ExamPro1
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) =>
            onChangeResult(questionId, answer)
          }
          listReview={listReview}
          onCheckReview={handleCheckReview}
          isDoing
        />
      );
    } else if (category.skill === "reading") {
      return (
        <ExamReading1
          type={"reading" + category?.question_type}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) =>
            onChangeResult(questionId, answer)
          }
          listReview={listReview}
          onCheckReview={handleCheckReview}
          isDoing
        />
      );
    } else if (category.skill === "writing") {
      return (
        <ExamWriting
          type={"writing" + category?.question_type}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) =>
            onChangeResult(questionId, answer)
          }
          listReview={listReview}
          onCheckReview={handleCheckReview}
          isDoing
        />
      );
    } else if (category.skill === "listening") {
      return (
        <ExamListening
          type={"listening" + category?.question_type}
          category={category}
          indexQuestion={indexQuestion}
          resultExam={resultExam}
          onChangeResult={(questionId, answer) =>
            onChangeResult(questionId, answer)
          }
          listReview={listReview}
          onCheckReview={handleCheckReview}
          isDoing
        />
      );
    } else if (category.skill === "grammar") {
      if (category?.question_type === "1") {
        return (
          <ExamGrammar1
            category={category}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) =>
              onChangeResult(questionId, answer)
            }
            listReview={listReview}
            onCheckReview={handleCheckReview}
            isDoing
          />
        );
      } else if (
        category?.question_type === "9" ||
        category?.question_type === "11"
      ) {
        return (
          <ExamGrammar9
            category={category}
            indexQuestion={indexQuestion}
            resultExam={resultExam}
            onChangeResult={(questionId, answer) =>
              onChangeResult(questionId, answer)
            }
            listReview={listReview}
            onCheckReview={handleCheckReview}
            isDoing
          />
        );
      }
    }
  };

  const renderModalConfirm = () => {
    if (actionConfirm === "SAVE_EXAM") {
      return (
        <PopUpYesNo
          labelYes="Có"
          onClickYes={() => saveExamTest()}
          labelNo="Đóng"
          onClickNo={() => setActionConfirm("")}
          message="Bạn có chắn chắn muốn nộp bài trước thời hạn?"
        />
      );
    } else if (actionConfirm === "END_EXAM") {
      return (
        <PopUpYesNo
          labelYes="Đồng ý"
          hideButtonNo={true}
          onClickYes={() => saveExamTest()}
          message="Đã hết thời gian làm bài! Bài của bạn sẽ được tự động thu."
        />
      );
    } else if (actionConfirm === "CANCEL_EXAM") {
      return (
        <PopUpYesNo
          title="Bài kiểm tra vẫn chưa kết thúc!"
          labelYes="Có"
          onClickYes={() => {
            if(props?.isBackGuide) {
              setDoingExam(false)
              setActionConfirm("")
            } else {
              onClose()
            }
          }}
          labelNo="Đóng"
          onClickNo={() => setActionConfirm("")}
          message="Bạn có chắc muốn thoát ra không? Câu trả lời của bạn sẽ không được lưu."
        />
      );
    }
  };

  const handleStartExam = () => {
    startDoExam();
    setCurrentCategory(0);
    setIndexQuestion(1);
  };

  const handleChangeBoxReview = () => {
    const newCheckedList = [...checkedList];
    newCheckedList[currentCategory].checked =
      !newCheckedList[currentCategory].checked;
    setCheckedList(newCheckedList);

    let newQuestionsReview = [...questionsReview];
    if (newCheckedList[currentCategory].checked) {
      // setQuestionReview((prev) => [...prev, ])
      listQuestions[currentCategory].listQuestions?.forEach((question) => {
        !newQuestionsReview.includes(question.question_id) &&
          newQuestionsReview.push(question.question_id);
      });

      setQuestionsReview(newQuestionsReview);
    } else {
      listQuestions[currentCategory].listQuestions?.forEach((question) => {
        newQuestionsReview = newQuestionsReview.filter(
          (item) => item != question.question_id
        );
      });
      setQuestionsReview(newQuestionsReview);
    }
  };

  const handleGoBackNews = () => {
    history.push("/mock_test");
  };
  
  return (
    <div id="c-do-test-exam">
      {messageError.length > 0 && (
        <PopUpYesNo
          title="Đã xảy ra lỗi"
          labelYes="Đóng"
          hideButtonNo={true}
          onClickYes={() => setMessageError("")}
          message={messageError}
        />
      )}

      {!!msgPopup && msgPopup?.length > 0 && (
        <PopUpYesNo
          title="Thông báo"
          labelYes={
            msgPopup?.includes("nâng cấp tài khoản") ? "Nâng cấp" : "Đóng"
          }
          labelNo={msgPopup?.includes("nâng cấp tài khoản") ? "Đóng" : "Đóng"}
          hideButtonNo={msgPopup?.includes("nâng cấp tài khoản") ? false : true}
          onClickYes={() => {
            setMsgPopup("");
            setDoingExam(false);
            setExamInfo(null);
            setExamSelected && setExamSelected(null);
            if (msgPopup?.includes("nâng cấp tài khoản")) {
              history.push("/" + authentication?.role + "/more/license");
            }
          }}
          onClickNo={() => {
            setMsgPopup("");
            setDoingExam(false);
            setExamInfo(null);
            setExamSelected && setExamSelected(null);
          }}
          message={msgPopup}
        />
      )}
      {renderModalConfirm(actionConfirm)}
      <div className="sunE-container rel flex bg-main-img">
        <div className="header-modal">
          <div className="title-header">
            <img
              src="/assets/images/icon/ico_left.png"
              onClick={actionCancelExam}
            />
            <span style={{ marginLeft: 30 }}>
              {rootlessness
                ? "Bài kiểm tra đầu vào"
                : isDoing
                ? `${
                    ["mock_test"].includes(
                      examInfo?.lesson_type ||
                        examInfo?.exercise_type ||
                        examInfo?.type ||
                        type
                    )
                      ? "Bài thi "
                      : ""
                  } ${
                    examInfo?.exam_name || examInfo?.name || "Làm bài kiểm tra"
                  }`
                : heading || "Làm bài thi"}
            </span>
          </div>
          {isDoing && (
            <div className="text-right">
              <button
                className="btn btn-overview"
                onClick={() => showOverview(true)}
              >
                Mục lục
              </button>
              <button className="btn btn-end" onClick={actionSaveExam}>
                Nộp bài
              </button>
            </div>
          )}
        </div>
        {dataResult && (
          <ResultExam
            examInfo={examInfo}
            handleGoBackNews={handleGoBackNews}
            typeMockTest={typeMockTest}
            dataResult={dataResult}
            onClose={onClose}
            examId={examInfo.id}
            resultExam={resultExam}
            listQuestions={listQuestions}
            onNavigateDetail={() =>
              history.push(
                "/" +
                  authentication.role +
                  "/exam-test/history/" +
                  examInfo.user_exam_result_id
              )
            }
          />
        )}
        {isDoing && !msgPopup && (
          <div className="flex-1 scrollbar-custom pos_rel">
            {/* <div className="questions">
              {listQuestions.length > 0 &&
                renderQuestion(listQuestions[currentCategory])}

              <div className="checkbox_review_container">
                <CheckBoxSquare
                  label="Xem lại sau"
                  status={checkedList[currentCategory]?.checked}
                  onChange={handleChangeBoxReview}
                />
              </div>
            </div> */}
            {listQuestions.length > 0 &&
              listQuestions?.map((item, index) => (
                <div className="questions" key={item?.group_id} style={{position: 'relative'}}>
                  {renderQuestion(item)}
                  {/* <div className="checkbox_review_container" style={{top: 0, right: 0}}>
                    <CheckBoxSquare
                      label="Xem lại sau"
                      status={checkedList[index]?.checked}
                      onChange={() => handleChangeBoxReviewV2(index)}
                    />
                  </div> */}
                </div>
              ))
            }
          </div>
        )}
        {isDoing && duration != 0 ? (
          <div className="footer-exam">
            {/* <button
              className="btn btn-primary"
              onClick={backCategory}
              disabled={currentCategory === 0}
            >
              <img src="/assets/images/icon/ico_left_white.png" />
            </button> */}
            <TimerTick
              seconds={parseInt(duration) * 60}
              onEndTimerTick={() => actionEndExam()}
            />
            {/* <button
              className="btn btn-primary"
              onClick={nextCategory}
              disabled={currentCategory === listQuestions.length - 1}
            >
              <img src="/assets/images/icon/ico_right_white.png" />
            </button> */}
          </div>
        ) : (
          !isLoading &&
          !msgPopup && (
            <InstructTestExam
              detailMockTest={detailMockTest}
              examInfo={examInfo}
              handleStartExam={handleStartExam}
              isEntranceTest={rootlessness || type != "mock_test"}
            />
          )
        )}
      </div>
      {isShowOverview && (
        <ModalOverviewExam
          open={isShowOverview}
          onClose={() => showOverview(false)}
          dataAnswers={formatListAnswer()}
          onShowQuestion={onShowQuestion}
          // questionsReviewLater={questionsReview}
          questionsReviewLater={listReview?.map(item => {
            if(item?.checked) return item?.question_id
            return null
          })?.filter(item => !!item)}
        />
      )}
    </div>
  );
};

export default React.memo(DoTestExamModal);
