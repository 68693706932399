import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import {
  alertConstants,
  configConstants,
  userConstants,
} from "../../../../_constants";
// import { Alert } from "../../../../_components/Alert";
import InputText from "../../../../_components/Auth/InputText";
import InputDate from "../../../../_components/Auth/InputDate";
import "./index.scss";
import InputSelect from "../../../../_components/Auth/InputSelect";
import { apiCaller, history } from "../../../../_helpers";
import {
  renderBirthdayIcon,
  renderLocationIcon,
  renderNameIcon,
  renderPhoneIcon,
  renderSchoolIcon,
} from "./renderIcon";
import { stepAuthConstants } from "../../../../_constants/auth";
import dayjs from "dayjs";
import { Alert } from "../../../../_components/Alert";
import { PopUpYesNo } from "../../../../_components/Popup";
import InputSelectSearch from "../../../../_components/Auth/InputSelectSearch";

const UpdateInformation = (props) => {
  const [fullname, setFullname] = useState(props.data.fullname.trim());
  const [birthday, setBirthday] = useState(props.data.birthday);
  const [phone, setPhone] = useState(props.data.phone);
  const [gender, setGender] = useState(props.data.gender);
  const [school, setSchool] = useState({
    title: props.data.school,
    value: props.data.school,
    id: props.data.school,
  });
  // address
  const [provinceList, setProvinceList] = useState([]);
  const [province, setProvince] = useState({
    title: "",
    value: "",
  });
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState({
    title: "",
    value: "",
  });
  const [schoolList, setSchoolList] = useState([]);
  // warning
  const [nameWarning, setNameWarning] = useState("");
  const [schoolWarning, setSchoolWarning] = useState("");
  const [birthdayWarning, setBirthdayWarning] = useState("");
  const [phoneWarning, setPhoneWarning] = useState("");
  const [errProvince, setErrProvince] = useState("");
  const [errDistrict, setErrDistrict] = useState("");

  useEffect(() => {
    // login social
    if (fullname !== props.data.fullname) {
      setFullname(props.data.fullname.trim());
    }
  }, [props.data.fullname]);

  useEffect(() => {
    if (
      props.step === stepAuthConstants.STEP_UPDATE_ACCOUNT &&
      !provinceList.length
    ) {
      getProvinceList();
    }
    // check voi truong hop la parent hoac giao vien tu do
    if (district.value && !schoolList.length) {
      // console.log("get school");
      getSchoolList(district.value);
    }
  }, [props.step]);

  const getProvinceList = () => {
    apiCaller("/api_category/provinces?country_code=vn").then((res) => {
      if (res.status) {
        setProvinceList(res.data);
      }
    });
  };

  const getDistrictList = (provinceAlias) => {
    apiCaller(`/api_category/districts?province_alias=${provinceAlias}`).then(
      (res) => {
        if (res.status) {
          setDistrictList(res.data);
        }
      }
    );
  };

  const getSchoolList = (districtAlias) => {
    if (
      props.role !== userConstants.ROLE_TEACHER ||
      props.schoolType !== userConstants.SCHOOL_TYPE_FREE
    ) {
      apiCaller(`/api_category/schools?district_alias=${districtAlias}`).then(
        (res) => {
          if (res.status) {
            setSchoolList(res.data);
          }
        }
      );
    }
  };

  const changeProvice = (newProvince) => {
    if (newProvince.value !== province.value) {
      setDistrict({
        title: "",
        value: "",
      });
      setSchool({
        title: "",
        value: "",
      });
      setProvince(newProvince);
      getDistrictList(newProvince.value);
    }
  };

  const changeDistrict = (newDistrict) => {
    if (newDistrict.value !== district.value) {
      setSchool({
        title: "",
        value: "",
      });
      setDistrict(newDistrict);
      getSchoolList(newDistrict.value);
    }
  };

  const changeSchool = (school) => {
    setSchool(school);
  };

  const onBlurPhone = () => {
    if (phone && !phoneWarning) {
      if (phone.length !== 10 && phone.length !== 11) {
        setPhoneWarning("Số điện thoại hợp lệ có từ 10-11");
      }
      if (phone && phone.length && phone[0] !== "0") {
        setPhoneWarning("Định dạng số điện thoại không đúng");
      }
    }
    return;
  };

  function handleSubmit(e) {
    e.preventDefault();
    // setSubmitted(true);
    if (validateParam()) {
      props.setData({
        fullname,
        birthday,
        gender,
        // phone,
        province: province.value,
        district: district.value,
        school: school.value,
        onRegister: true,
        school_id: school?.id,
      });
    } else {
      if (!fullname) {
        setNameWarning("Vui lòng nhập họ tên");
      }
      // if (!phone) {
      //   setPhoneWarning("Vui lòng nhập số điện thoại");
      // } else if (phone.length !== 10 && phone.length !== 11) {
      //   setPhoneWarning("Số điện thoại hợp lệ có từ 10 -11");
      // } else if (phone && phone.length && phone[0] !== "0") {
      //   setPhoneWarning("Định dạng số điện thoại không đúng");
      // }

      if (!school) {
        setSchoolWarning("Vui lòng nhập tên trường");
      }
    }
  }

  function validateParam() {
    if (fullname.trim() && birthday && gender) {
      // if (
      //   (props.role === userConstants.ROLE_STUDENT && !school.value) ||
      //   (props.role === userConstants.ROLE_TEACHER &&
      //     props.schoolType !== userConstants.SCHOOL_TYPE_FREE &&
      //     !school.value) ||
      //   (props.role === userConstants.ROLE_TEACHER &&
      //     props.schoolType === userConstants.SCHOOL_TYPE_FREE &&
      //     !district.value) ||
      //   (props.role === userConstants.ROLE_PARENT && !province.value)
      // ) {
      //   return false;
      // }
      // if(!!props?.org ) {
      //   if(!phone)
      //   return false
      //   else if (((phone.length != 10 && phone.length != 11) || phone[0] != "0"))
      //     return false
      // }
      // if (
      //   props.role != userConstants.ROLE_STUDENT &&
      //   ((phone.length != 10 && phone.length != 11) || phone[0] != "0")
      // ) {
      //   return false;
      // }
      return true;
    }
    return false;
  }

  function changeGender(gender) {
    setGender(gender);
  }

  /**
   * changePhone change và validate phone
   * @param value value change
   */
  // const changePhone = (value) => {
  //   const validatedValue = value.replace(/[^0-9]/g, "");
  //   setPhone(validatedValue);
  //   if (phoneWarning) {
  //     if (value.length === 10 || value.length === 11) {
  //       setPhoneWarning("");
  //     }
  //   }
  // };

  const changeBirthday = (birthday) => {
    const today = new Date();
    if (dayjs(birthday).isBefore(dayjs(today))) {
      setBirthday(birthday);
      // setBirthdayWarning("");
    } else {
      setBirthday(today);
      // setBirthdayWarning("Ngày sinh không được ở tương lai");
    }
  };

  return (
    <div className="sunE-form-container login-form-container register_update_info register_step">
      <div className="sunE-custom-form register_update_info_content responsive_small_screen_margin_login">
        <h2 className="title_register title text-center">ĐĂNG KÝ TÀI KHOẢN</h2>
        <h3 className="step_register_name text-center">
          {`${props?.org ? 4 : 3}. Cập nhật thông tin`}
        </h3>
        {/* {!isEmpty(props.messageTeacher) && <Alert alert={{type: alertConstants.ERROR, message: props.messageTeacher}}/>} */}
        {/* {alert.message && alert.screen === userConstants.SCREEN_COMPLETE_DATA_REGISTER && (
                    <Alert alert={alert} onComplete={() => history.push("/login")} />
                )} */}

        {props.popupMessageRegisterTeacher && (
          <PopUpYesNo
            message={props.messageTeacher}
            hideButtonYes={!props.statusSuccessRegisterTeacher}
            hideButtonNo={props.statusSuccessRegisterTeacher}
            labelYes={"Đồng ý"}
            onClickYes={props.onClickYes}
            onClickNo={() => props.setPopupMessageRegisterTeacher(false)}
          />
        )}

        <form
          className="sunE-login-app flex-center-column"
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="off"
        >
          <div className="form_container">
            <div className="left">
              <div className="text-center field_detail_group_create_account">
                Thông tin cá nhân
              </div>
              <InputText
                className="field_item"
                value={fullname}
                setValue={setFullname}
                type="text"
                name="name"
                placeholder="Họ tên"
                required={true}
                renderLabelIcon={renderNameIcon}
                removeWarningOnType={true}
                errorText={nameWarning}
                setErrorText={setNameWarning}
                errorAbsolute={true}
                onBlur={() =>
                  fullname.trim() === ""
                    ? setFullname("")
                    : setFullname(fullname.trim())
                }
                autoFocus={true}
              ></InputText>
              <div className="flex-m field_item">
                <InputDate
                  className="field_birthday field_item"
                  value={birthday}
                  setValue={changeBirthday}
                  name="birthday"
                  renderLabelIcon={renderBirthdayIcon}
                  errorText={birthdayWarning}
                  setErrorText={setBirthdayWarning}
                  errorAbsolute={true}
                  placeholder={"Ngày sinh"}
                  maxDate={new Date()}
                ></InputDate>
                <div
                  className={"d-flex gr-gender justify-content-center " + (gender === "" ? "err" : "")}
                >
                  <div
                    className={
                      "register_update_info_content_gender gender_male" +
                      (gender === "male" ? " active" : "")
                    }
                    // style={{marginLeft: 0}}
                    onClick={() => changeGender("male")}
                  >
                    <div className="content">
                      <img
                        src="assets/images/auth/img_gen_male_active.png"
                        alt={"ico_male"}
                        className="male_active"
                      />
                      <img
                        src="assets/images/auth/img_gen_male_disabled.png"
                        alt={"ico_male_2"}
                        className="male"
                      />
                      {/* <div>
                        <span>Nam</span>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className={
                      "register_update_info_content_gender gender_female" +
                      (gender === "female" ? " active" : "")
                    }
                    onClick={() => changeGender("female")}
                  >
                    <div className="content">
                      <img
                        src="assets/images/auth/img_gen_female_disabled.png"
                        alt={"ico_female"}
                        className="female"
                      />
                      <img
                        src="assets/images/auth/img_gen_female_active.png"
                        alt={"ico_female_2"}
                        className="female_active"
                      />
                      {/* <div>
                        <span>Nữ</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <InputText
                className="field_item"
                value={phone}
                setValue={changePhone}
                type="text"
                name="email"
                placeholder={
                  props.role == userConstants.ROLE_TEACHER
                    ? `Nhập số điện thoại đăng ký Zalo của bạn`
                    : `Số điện thoại`
                }
                required={true}
                renderLabelIcon={renderPhoneIcon}
                errorText={phoneWarning}
                setErrorText={setPhoneWarning}
                onBlur={onBlurPhone}
                errorAbsolute={true}
              ></InputText> */}
            </div>
            <div className="right">
              <div className="text-center field_detail_group_create_account">
                {props.role !== userConstants.ROLE_TEACHER
                  ? `Nơi học tập`
                  : `Nơi công tác`}
              </div>
              <InputSelectSearch
                className="field_item field_province"
                value={province}
                setValue={changeProvice}
                options={provinceList.map((item) => {
                  return {
                    value: item.province_alias,
                    title: item.province,
                  };
                })}
                placeholder="Tỉnh/Thành phố"
                titleHeader="Chọn Tỉnh/Thành phố"
                renderLabelIcon={renderLocationIcon}
                errorText={errProvince}
                setErrorText={setErrProvince}
                errorAbsolute={true}
              ></InputSelectSearch>
              <InputSelectSearch
                className="field_item field_district"
                value={district}
                setValue={changeDistrict}
                options={districtList.map((item) => {
                  return {
                    value: item.district_alias,
                    title: item.district,
                  };
                })}
                placeholder="Quận/Huyện"
                titleHeader="Chọn Quận/Huyện"
                renderLabelIcon={renderLocationIcon}
                disabledClick={!province.value}
                onClickDisable={() => {
                  setErrProvince("Vui lòng chọn tỉnh / thành phố");
                }}
                errorText={errDistrict}
                setErrorText={setErrDistrict}
                errorAbsolute={true}
              ></InputSelectSearch>
              {props.role === userConstants.ROLE_TEACHER &&
              props.schoolType === userConstants.SCHOOL_TYPE_FREE ? null : (
                <InputSelectSearch
                  className="field_item field_school"
                  value={school}
                  setValue={changeSchool}
                  options={schoolList.map((item) => {
                    return {
                      value: item.school_name,
                      title: item.school_name,
                      id: item?.id,
                    };
                  })}
                  placeholder="Trường"
                  titleHeader="Chọn Trường"
                  renderLabelIcon={renderSchoolIcon}
                  disabledClick={!district.value}
                  onClickDisable={() => {
                    setErrDistrict("Vui lòng chọn quận / huyện");
                    if (!province.value) {
                      setErrProvince("Vui lòng chọn tỉnh / thành phố");
                    }
                  }}
                  errorAbsolute={true}
                ></InputSelectSearch>
              )}
            </div>
          </div>
          <div className="button_submit">
            <button
              className={
                "btn-line-blue" + (!validateParam() ? " btn-disable" : "")
              }
            >
              Hoàn tất
            </button>
          </div>
          <div className="login_text text-center no_select flex-center">
            <p
              onClick={() => {
                props.setStep(props?.org ? stepAuthConstants.STEP_ADD_CLASS_NAME : stepAuthConstants.STEP_ACCOUNT_TYPE);
              }}
              style={{
                width: "fit-content",
              }}
            >
              Quay lại
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateInformation;
