import React, { useEffect, useState } from "react";
import { teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { ContentSkill } from "./";
import { useHistory } from "react-router-dom";
import { teacherConstants } from "../../../_constants";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { isEmpty } from "lodash";

function ViewDetailCurriculumListExercise() {
  const { id, unit_id, skill } = useParams();

  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const typeAssign = params?.get("assign");
  const userID = params?.get("user_id");
  const lessonSkill = useSelector((state) => state.curriculums.lesson_skill);

  const fromPage = params?.get("page");
  // useEffect(() => {
  //   dispatch(teacherActions.getDetailCurriculumById(id));
  // }, []);
  const dispatch = useDispatch();
  const curriculums = useSelector(
    (state) => state.curriculums.detail.data_lesson.unit_name
  );
  let currentCurriculum = curriculums.find((item) => item.unit_id === unit_id);
  let currentListSkill;
  if (!isEmpty(lessonSkill?.data)) {
    currentListSkill = lessonSkill.data?.find(
      (item) => item.skill == skill
    )?.list_lesson;
  } else if (!isEmpty(currentCurriculum?.list_skill)) {
    currentListSkill = currentCurriculum.list_skill?.find(
      (item) => item.skill === skill
    ).lesson_in_skill;
  }
  const exercises = useSelector((state) => state.classes.exercises);
  const [saveListExercise, setSaveListExercise] = useState(
    exercises.data_exercise
  );

  useEffect(() => {
    exercises.data_exercise = saveListExercise;
    dispatch({ type: teacherConstants.ADD_DATA_EXERCISE, data: exercises });
  }, [saveListExercise]);

  useEffect(() => {
    if (fromPage != "curriculum") {
      teacherService.getLessonBySkillTeacher(unit_id).then((res) => {
        if (res) {
          dispatch({
            type: curriculumConstants.LESSON_BY_SKILL_TEACHER,
            lesson_skill: res,
          });
        }
      });
    }
  }, []);

  const titleHeader = skill.charAt(0).toUpperCase() + skill.slice(1);

  return (
    <div className='sunE-right-container'>
      <Header title={titleHeader?.capitalize()?.replace("_", " ")} isBack />
      <div className='sunE-container-box giaotrinh filter flex-column'>
        {currentListSkill && (
          <ContentSkill
            data={currentListSkill}
            textEmpty='Bạn chưa có bài tập yêu thích nào.'
            typeAssign={typeAssign}
            userID={userID}
            setSaveListExercise={setSaveListExercise}
          />
        )}
      </div>
    </div>
  );
}

export { ViewDetailCurriculumListExercise };
